/****************************************Desarrollo****************************************/
// export const config = {
//     urlSweetAPI: "http://dlabsdbdev:2622",
//     urlKiwiAPI: "http://localhost:5000/",
//     //urlKiwiAPI: "http://dlabsdbdev:2526/",
//     krakenKey: "A70CEE06-5784-4085-BC46-05D2E986D568",
//     urlKrakenAPI: "http://dlabsdbdev:2022/KrakenServices",
//     urlKrakenLogistics: "http://dlabsdbdev:2022/KrakenLogistics",
// };

/****************************************Sandbox****************************************/
// export const config = {
//     urlSweetAPI: "http://dlabssandbox:2622",
//     urlKiwiAPI: "http://dlabssandbox:2526/",
//     krakenKey: "A70CEE06-5784-4085-BC46-05D2E986D568",
//     urlKrakenAPI: "http://dlabssandbox:2022/KrakenServices",
//     urlKrakenLogistics: "http://dlabssandbox:2022/KrakenLogistics",
// };

/****************************************Produccion****************************************/
export const config = {
    urlSweetAPI: "https://sweetsrv.azurewebsites.net",
    urlKiwiAPI: "https://kiwisrvcloud.azurewebsites.net/",
    krakenKey: "A70CEE06-5784-4085-BC46-05D2E986D568",
    urlKrakenAPI: "https://krakenapi.deacero.com/KrakenServices",
    urlKrakenLogistics: "https://krakenapi.deacero.com/KrakenLogistics",
};
