import React, { Component } from "react";
import Componente from "./Componente";
import { descargarArchivosDashboardZip } from "../../utils/files";
import { showNotify, notifyPosition, notifyType } from "../../utils/utils";
import TextBoxComponente from "./TextBoxComponente";
import { Button } from "devextreme-react/button";
import {metricaAccion} from "../../utils/metrica"

var componentesTemp = [],
    datosTemp = [],
    cont = 0;

class Generador extends Component {
    constructor(props) {
        super(props);

        this.state = {
            componentes: [],
            datos: [],
            nombreDashboard: "",
        };
    }
    /*Resetea el estado a estado inicial*/
    removeAll = () => {
        componentesTemp = [];
        datosTemp = [];
        cont = 0;
        this.setState({ componentes: componentesTemp, datos: datosTemp, nombreDashboard: "" });
    };
    /*Elimina el componente*/
    removeComponente = (componente) => {
        //Filtro los componentes y desprecio el eliminado para setearlo al estado actual
        componentesTemp = componentesTemp.filter((item) => item.props.children.props.id != componente.props.id);

        //Elimino la informacion o datos de ese componente
        datosTemp.splice(datosTemp.indexOf(datosTemp.find((item) => item.id === componente.props.id)), 1);

        this.setState({ componentes: componentesTemp, datos: datosTemp });
    };
    /*Agrega un nuevo componente */
    addComponente = () => {
        cont++;

        componentesTemp.push(
            <li key={"cmp_" + cont}>
                <Componente
                    id={cont}
                    onRemoveComponente={this.removeComponente}
                    onUpdateComponente={this.updateComponente}
                />
            </li>
        );
        this.setState({ componentes: componentesTemp });
    };
    /*Maneja la actualizacion del nombre del DashBoard*/
    updateNombreDashboard = (nomDashboard) => {
        console.log(nomDashboard);
        this.setState({ nombreDashboard: nomDashboard });
    };
    /*Maneja la actualizacion de componentes*/
    updateComponente = (obj) => {
        datosTemp.push(obj);

        //Reemplazo al mismo objeto en el arreglo de datos
        let datosReduce = datosTemp.reduce((map, item) => {
            map[item.id] = item;
            return map;
        }, {});

        datosTemp = Object.keys(datosReduce).map((i) => datosReduce[i]);

        this.setState({ datos: datosTemp });
    };
    /*Genera los archivos de codigo*/
    generarDashoard = () => {
        let componentes = [];

        //Busco los componentes registrados
        this.state.datos.forEach((item) => {
            let componente = {};
            componente.tipoComponente = parseInt(item.componentePlantilla[0]);
            componente.tipoPlantilla = parseInt(item.componentePlantilla[1]);
            componente.nombreArchivo = item.archivo;
            componente.tituloComponente = item.titulo;

            componentes.push(componente);
        });

        if (!this.state.nombreDashboard) {
            showNotify(
                "Ops! no haz agregado un nombre para el nuevo dashboard!",
                notifyPosition.centerTop,
                notifyType.error
            );

            return;
        }

        //Si hay por lo menos un componente con sus datos completos se generan los archivos
        if (componentes.length) {
            descargarArchivosDashboardZip(this.state.nombreDashboard, componentes);

            showNotify("Generación exitosa!", notifyPosition.centerTop, notifyType.success);

            metricaAccion(window.location.href,"Genero un Dashboard");

            //Resetea el estatus al inicio
            this.removeAll();
        } else {
            showNotify(
                "Por lo menos debes llenar los datos de un Indicador/Componente!",
                notifyPosition.centerTop,
                notifyType.error
            );
        }
    };
    onFormSubmit = (e) => {
        // Se envia la generacion del codigo del nuevo dashboard
        this.generarDashoard();

        e.preventDefault();
    };
    componentDidMount() {
        document.getElementById("nombreDashboard").innerText = "Generador";
        metricaAccion(window.location.href,"Entro a Generador");

        //Mantengo la informacion actual en las temporales
        componentesTemp = this.state.componentes;
        datosTemp = this.state.datos;

        //Componente Default
        this.addComponente();
    }
    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
        }
    }
    render() {
        return (
            <div id="generador" className="container" style={{ position: "relative", top: -65 }}>
                <div
                    id="headerGenerador"
                    className="row justify-content-center"
                    style={{ boxShadow: "5px 6px 7px -3px rgb(72 72 72)", borderRadius: 10 }}
                >
                    <div className="col-12" style={{ backgroundColor: "#f96332", height: 120, borderRadius: 10 }}>
                        <div className="d-flex">
                            <div
                                style={{
                                    width: 500,
                                    padding: 10,
                                    margin: 11,
                                    color: "white",
                                    fontWeight: 500,
                                    fontSize: "medium",
                                }}
                            >
                                <label>Nombre del Dashboard</label>
                                <div
                                    style={{
                                        borderRadius: 17,
                                        borderColor: "#e3e3e3",
                                        borderStyle: "solid",
                                        borderWidth: 1,
                                    }}
                                >
                                    <TextBoxComponente
                                        className={"genTxtHeader"}
                                        value={this.state.nombreDashboard}
                                        placeHolder="Nombre del DashBoard"
                                        onUpdate={this.updateNombreDashboard}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <form onSubmit={this.onFormSubmit}>
                    <ul key="listaComponentes" style={{ padding: 0, margin: 10 }}>
                        {this.state.componentes}
                    </ul>

                    <Button
                        className="btn btn-primary btn-round float-right"
                        onClick={this.addComponente}
                        style={{ marginTop: 0 }}
                        useSubmitBehavior={false}
                    >
                        Agregar
                    </Button>

                    <Button
                        className="btn btn-primary btn-round float-right"
                        style={{ marginRight: 10, marginTop: 0 }}
                        useSubmitBehavior={true}
                    >
                        Generar
                    </Button>
                </form>
            </div>
        );
    }
}

export default Generador;
