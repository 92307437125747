import React, { Component } from "react";
import { Popup } from "devextreme-react/popup";
import { Button, Card, CardHeader, CardBody, CardTitle, Row, Col } from "reactstrap";
import DataGrid, { Column, Paging, Editing, Lookup, FilterRow } from "devextreme-react/data-grid";
import { callApi, showNotify, notifyType, notifyPosition, callKrakenApi } from "../../utils/utils";
import { config } from "../../utils/config";
import UsuarioAlta from "./UsuarioAlta";

class Usuarios extends Component {
    constructor(props) {
        super(props);

        this.state = {
            usuarios: [],
            perfiles: [],
            ubicaciones: [],
            popupVisible: false,
            subproductos: [],
        };

        this.onRowUpdated = this.onRowUpdated.bind(this);
        this.onRowRemoved = this.onRowRemoved.bind(this);
    }

    /**
     * Funcion que se abre el popup para dar de alta un usuario
     * @param {Obj} e objeto del popup
     */
    onClickAgregar = (e) => {
        this.setState({
            popupVisible: true,
        });

        e.stopPropagation();
    };

    /**
     * Funcion que se oculta el popup de alta de usuario
     */
    hidePopUp = () => {
        this.setState({
            popupVisible: false,
        });
    };

    /**
     * Funcion que actualiza el estatus del popup de alta de usuario
     * @param {Boolean} val estatus del popop
     */
    handler = (val) => {
        this.setState({
            popupVisible: val,
        });
    };

    /**
     * Funcion que se ejecuta al actualizar un usuario
     * @param {Obj} e objeto del row que se esta actualizando
     */
    onRowUpdated(e) {
        // Opcion para dar de actualizar
        let opcion = 2;
        let claEmpleado = e.data.claEmpleado;
        let claPerfil = e.data.claPerfil;
        let claUbicacion = e.data.claUbicacion;
        let claSubProducto = e.data.claSubProducto;

        let urlWebService = config.urlKiwiAPI + "usuarios/usuariosCRUD/" + opcion;

        let body = {
            claEmpleado: claEmpleado,
            claPerfil: claPerfil,
            claUbicacion: claUbicacion,
            claSubProducto: claSubProducto,
        };

        try {
            //Petición al API de Net Core
            const functionCallback = (res) => {
                showNotify("Usuario actualizado", notifyPosition.centerTop, notifyType.success);
            };

            callApi(urlWebService, "PUT", body, functionCallback).catch((err) => {
                showNotify("Ha ocurrido un error: " + err.message, notifyPosition.centerTop, notifyType.error);
                console.log("Error: " + err.message);
            });
        } catch (err) {
            showNotify("Ha ocurrido un error: " + err.message, notifyPosition.centerTop, notifyType.error);
            console.log("Error: " + err.message);
        }
    }

    /**
     * Funcion que se ejecuta al dar de baja un usuario
     * @param {Obj} e objeto del row que se esta dando de baja
     */
    onRowRemoved(e) {
        // Opcion para dar de baja
        let opcion = 3;
        let claEmpleado = e.data.claEmpleado;
        let claPerfil = e.data.claPerfil;
        let claUbicacion = e.data.claUbicacion;
        let claSubProducto = e.data.claSubProducto;

        let urlWebService = config.urlKiwiAPI + "usuarios/usuariosCRUD/" + opcion;

        let body = {
            claEmpleado: claEmpleado,
            claPerfil: claPerfil,
            claUbicacion: claUbicacion,
            claSubProducto: claSubProducto,
        };

        try {
            //Petición al API de Net Core
            const functionCallback = (res) => {
                showNotify("Usuario eliminado", notifyPosition.centerTop, notifyType.success);
            };

            callApi(urlWebService, "PUT", body, functionCallback).catch((err) => {
                showNotify("Ha ocurrido un error: " + err.message, notifyPosition.centerTop, notifyType.error);
                console.log("Error: " + err.message);
            });
        } catch (err) {
            showNotify("Ha ocurrido un error: " + err.message, notifyPosition.centerTop, notifyType.error);
            console.log("Error: " + err.message);
        }
    }

    /**
     * Funcion que obtiene la lista de usuarios
     */
    getUsuarios() {
        let urlWebService = "";

        urlWebService = config.urlKiwiAPI + "usuarios/usuariosObtener/0";
        //Petición al API de Net Core
        try {
            const functionCallback = (res) => {
                this.setState({
                    usuarios: res.data,
                });
            };

            callApi(urlWebService, "GET", null, functionCallback).catch((err) => {
                showNotify("Ha ocurrido un error: " + err.message, notifyPosition.centerTop, notifyType.error);
                console.log("Error: " + err.message);
            });
        } catch (err) {
            showNotify("Ha ocurrido un error: " + err.message, notifyPosition.centerTop, notifyType.error);
            console.log("Error: " + err.message);
        }
    }

    /**
     * Funcion que obtiene los perfiles
     */
    getPerfiles() {
        let urlWebService = "";

        urlWebService = config.urlKiwiAPI + "usuarios/usuariosTiposPerfiles";
        console.log(urlWebService);
        //Petición al API de Net Core
        try {
            const functionCallback = (res) => {
                this.setState({
                    perfiles: res.data,
                });
            };

            callApi(urlWebService, "GET", null, functionCallback).catch((err) => {
                showNotify("Ha ocurrido un error: " + err.message, notifyPosition.centerTop, notifyType.error);
                console.log("Error: " + err.message);
            });
        } catch (err) {
            showNotify("Ha ocurrido un error: " + err.message, notifyPosition.centerTop, notifyType.error);
            console.log("Error: " + err.message);
        }
    }

    /**
     * Funcion que obtiene las ubicaciones
     */
    getUbicaciones() {
        let krakenParams = {
            ClaProducto: 8,
            IdEntidad: 72,
            TipoEstructura: 1,
            ParamsSP: null,
            Callback: null,
        };

        krakenParams.ParamsSP = {
            ApiKey: config.krakenKey,
        };

        krakenParams.Callback = (response) => {
            this.setState({ ubicaciones: response.Result0 });
        };

        //Hacemos la peticion para el servicio el indicador
        callKrakenApi(
            krakenParams.ClaProducto,
            krakenParams.IdEntidad,
            krakenParams.ParamsSP,
            krakenParams.TipoEstructura,
            krakenParams.Callback
        );
    }

    getSubProductos() {
        let urlWebService = "";

        urlWebService = config.urlKiwiAPI + "usuarios/usuariosObtenerSubProductos";
        console.log(urlWebService);
        //Petición al API de Net Core
        try {
            const functionCallback = (res) => {
                this.setState({
                    subproductos: res.data,
                });
            };

            callApi(urlWebService, "GET", null, functionCallback).catch((err) => {
                showNotify("Ha ocurrido un error: " + err.message, notifyPosition.centerTop, notifyType.error);
                console.log("Error: " + err.message);
            });
        } catch (err) {
            showNotify("Ha ocurrido un error: " + err.message, notifyPosition.centerTop, notifyType.error);
            console.log("Error: " + err.message);
        }
    }

    componentDidMount() {
        this.getUsuarios();

        this.getPerfiles();

        this.getUbicaciones();

        this.getSubProductos();
    }

    render() {
        const GridUsuarios = (
            <DataGrid
                dataSource={this.state.usuarios}
                showRowLines={true}
                onRowUpdated={this.onRowUpdated}
                onRowRemoved={this.onRowRemoved}
                noDataText={"Sin Registros"}
            >
                <FilterRow visible={true} showAllText={"Todos"} />
                <Paging defaultPageSize={20} />
                {/* <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showInfo={true} /> */}
                <Editing mode="row" allowUpdating={true} allowDeleting={true} allowAdding={false} width={200} />

                <Column
                    dataField="claEmpleado"
                    caption="Clave Empleado"
                    allowEditing={false}
                    alignment={"center"}
                    width={200}
                />
                <Column dataField="nombre" caption="Nombre" allowEditing={false} />

                <Column dataField="claSubProducto" caption="SubProducto" allowEditing={false}>
                    <Lookup
                        dataSource={this.state.subproductos}
                        displayExpr="nomSubProducto"
                        valueExpr="claSubProducto"
                    />
                </Column>

                <Column dataField="claPerfil" caption="Perfil">
                    <Lookup dataSource={this.state.perfiles} displayExpr="nomPerfil" valueExpr="claPerfil" />
                </Column>

                <Column dataField="claUbicacion" caption="Ubicación">
                    <Lookup dataSource={this.state.ubicaciones} displayExpr="NomUbic" valueExpr="ClaUbic" />
                </Column>
            </DataGrid>
        );

        const GridUsuariosServicio = () => {
            return <div>{GridUsuarios}</div>;
        };

        return (
            <>
                <div className="content">
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <CardTitle tag="h4">Configuración de Usuarios</CardTitle>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md={{ size: 1, offset: 11 }} style={{ textAlign: "center" }}>
                                    <Button
                                        id={`btnNuevo`}
                                        onClick={this.onClickAgregar}
                                        size="md"
                                        className="btn btn-primary btn-round btn-icon btn-custom"
                                        style={{ backgroundColor: "#fa7a50;" }}
                                    >
                                        <i className="fa fa-plus" />
                                    </Button>
                                </Col>
                            </Row>
                            <GridUsuariosServicio />
                        </CardBody>
                    </Card>
                </div>

                <Popup
                    key={"popUpUsuarios"}
                    id={"popUpUsuarios"}
                    visible={this.state.popupVisible}
                    onHiding={this.hidePopUp}
                    dragEnabled={false}
                    closeOnOutsideClick={true}
                    showTitle={true}
                    title="Alta de Usuario"
                    width={470}
                    height={570}
                >
                    <UsuarioAlta
                        handler={this.handler}
                        perfiles={this.state.perfiles}
                        ubicaciones={this.state.ubicaciones}
                        subproductos={this.state.subproductos}
                    />
                </Popup>
            </>
        );
    }
}

export default Usuarios;
