export const ticketImplementacion = `
/*Titulo e Icono de Tickets del Grid de detalle*/
const renderIconTicket = () => {
    return (
        <div className="float-left d-inline-flex p-2">
            <h5 id={"tituloDetalle"}>#TD</h5>
            <span
                className="fa-stack text-success ml-2 logo-validacion"
                id={"ticketDetalle"}
                onClick={this.onClickTicketDetalle}
            >
                <i className="fas fa-certificate fa-stack-2x"></i>
                <i className="fas fa-check fa-stack-1x fa-inverse"></i>
            </span>
        </div>
    );
};

/*Elementos del Ticket #TD */
const Tickets = (
    <Popup
        key={"popUpTicket#TX"}
        id={"popUpTicket#TX"}
        visible={this.state.popupTicketVisible}
        onHiding={this.hidePopUpTicket}
        dragEnabled={false}
        closeOnOutsideClick={true}
        showTitle={true}
        title="Verificacion e Incidentes"
        width={800}
        height={600}
    >
        <Ticket idEntidad={6} claProducto={3} nombre={"#TD"} handler={this.handler}></Ticket>
    </Popup>
);

/*Elementos del Ticket #TD*/ 
const TicketsDetalle = (
    <Popup
        key={"popUpTicket#TXDetalle"}
        id={"popUpTicket#TXDetalle"}
        visible={this.state.popupTicketDetalleVisible}
        onHiding={this.hidePopUpTicketDetalle}
        dragEnabled={false}
        closeOnOutsideClick={true}
        showTitle={true}
        title="Verificacion e Incidentes"
        width={800}
        height={600}
    >
        <Ticket
            idEntidad={6}
            claProducto={3}
            nombre={"#TD"}
            handler={this.handler}
        ></Ticket>
    </Popup>
);

 /* Representa el icono de Ticket para el elemento Indicador */ 
const IconoTicket = (
    <span
        className="fa-stack text-success ml-2 logo-validacion"
        id={"ticket#TX"}
        onClick={this.onClickTicket}
    >
        <i className="fas fa-certificate fa-stack-2x"></i>
        <i className="fas fa-check fa-stack-1x fa-inverse"></i>
    </span>
);`;
