import JSZip from "jszip";
import FileSaver from "file-saver";
import { metodosControl } from "./plantillas/control/metodosControl";
import { popUpDetalle } from "./plantillas/control/popUpDetalle";
import { ticketImplementacion } from "./plantillas/control/ticketImplementacion";
import { graficaBarras } from "./plantillas/graficaBarras";
import { graficaBase } from "./plantillas/graficaBase";
import { graficaCircular } from "./plantillas/graficaCircular";
import { graficaMixta } from "./plantillas/graficaMixta";
import { graficaHotMap } from "./plantillas/graficaHotMap";
import { graficaSunburst } from "./plantillas/graficaSunburst";
import { indicadorSimple } from "./plantillas/indicadorSimple";
import { filtroMultiSelect } from "./plantillas/filtroMultiSelect";
import { filtroSelect } from "./plantillas/filtroSelect";
import { filtroBoton } from "./plantillas/filtroBoton";
import { filtroCheckBox } from "./plantillas/filtroCheckBox";
import { principal } from "./plantillas/principal";
import { indicadorDoble } from "./plantillas/indicadorDoble";

import { dashboards } from "./plantillas/dashboards";

let tokenPopupDetalle = /#ImpPD/gi;
let tokenImplementacionTickets = /#ImpTKT/gi;
let tokenMetodosDeControl = /#ImpMC/gi;
let tokenNombreComponente = /#Nombre/gi;
let tokenDetalleComponente = /#TD/gi;
let tokenDetalleComponenteTrim = /#TX/gi;

let tokenImportsPrincipal = /#ImpCNT/gi;
let tokenNombreDashboard = /#NombreDashboard/gi;
let tokenFiltros = /#ImpFLT/gi;
let tokenCallbacksFiltros = /#ImpMFLT/gi;
let tokenIndicadores = /#ImpIND/gi;
let tokenGraficas = /#ImpGRF/gi;

//Graficas Especiales
let tokenImportsGrafSunburst = /#ImpSUNBURTS/gi;
let tokenImportsGrafMap = /#ImpMAPS/gi;

//Constructor Type
let tokenHCImpContructorType = /#ImpCType/gi;

//Grafica SunBurst
let importSunburts = `
import HighchartsSunburst from "highcharts/modules/sunburst";
HighchartsSunburst(Highcharts)`;

//Grafica Mapa
let importMap = `
import HighchartsMaps from "highcharts/modules/map";
//https://www.npmjs.com/package/@highcharts/map-collection 
//npm i @highcharts/map-collection
import mapDataMexico from "@highcharts/map-collection/countries/mx/mx-all.geo.json";
HighchartsMaps(Highcharts);`;

//Tipos de Componentes
export var tipoComponente = {
    Grafica: 1,
    Indicador: 2,
    IndicadorDoble: 3,
    Filtro: 4,
};

//Tipos de Plantilla
export var tipoPlantilla = {
    GraficaBarra: 1,
    GraficaCircular: 8,
    GraficaMixta: 9,
    GraficaHotMap: 10,
    GraficaSunburst: 11,
    IndicadorSimple: 2,
    IndicadorDoble: 7,
    FiltroBoton: 3,
    FiltroCheckBox: 4,
    FiltroSelect: 5,
    FiltroMultiselect: 6,
};

/*Ejemplo de Dashboard devuelto por Wizard */
let dashboard = {
    nombre: "DashboardDemo",
    componentes: [
        {
            tipoComponente: 1,
            tipoPlantilla: 1,
            nombreArchivo: "GrafBarras1@$%^^&$ ",
            tituloComponente: "Barras",
        },
        {
            tipoComponente: 1,
            tipoPlantilla: 8,
            nombreArchivo: "GrafCircular",
            tituloComponente: "Circular",
        },
        {
            tipoComponente: 1,
            tipoPlantilla: 9,
            nombreArchivo: "GrafMixta",
            tituloComponente: "Mixta",
        },
        {
            tipoComponente: 1,
            tipoPlantilla: 10,
            nombreArchivo: "GrafMapa``",
            tituloComponente: "Mapa",
        },
        {
            tipoComponente: 1,
            tipoPlantilla: 11,
            nombreArchivo: "GrafSunBurn``",
            tituloComponente: "SunBurn",
        },
        {
            tipoComponente: 2,
            tipoPlantilla: 2,
            nombreArchivo: "Indicador1",
            tituloComponente: "Otra Mas",
        },
        {
            tipoComponente: 2,
            tipoPlantilla: 2,
            nombreArchivo: "Indicador2",
            tituloComponente: "Otra Mas",
        },
        {
            tipoComponente: 2,
            tipoPlantilla: 2,
            nombreArchivo: "Indicador3",
            tituloComponente: "Otra Mas",
        },
        {
            tipoComponente: 2,
            tipoPlantilla: 2,
            nombreArchivo: "Indicador4",
            tituloComponente: "Otra Mas",
        },
        {
            tipoComponente: 2,
            tipoPlantilla: 2,
            nombreArchivo: "Indicador5",
            tituloComponente: "Otra Mas",
        },
        {
            tipoComponente: 2,
            tipoPlantilla: 2,
            nombreArchivo: "Indicador6",
            tituloComponente: "Otra Mas",
        },
        {
            tipoComponente: 3,
            tipoPlantilla: 7,
            nombreArchivo: "IndicadorDoble",
            tituloComponente: "Indicador Doble",
        },
        {
            tipoComponente: 4,
            tipoPlantilla: 3,
            nombreArchivo: "Filtro1",
            tituloComponente: "Filtro Tipo Boton",
        },
        {
            tipoComponente: 4,
            tipoPlantilla: 4,
            nombreArchivo: "Filtro2",
            tituloComponente: "Filtro Tipo CheckBox",
        },
        {
            tipoComponente: 4,
            tipoPlantilla: 5,
            nombreArchivo: "Filtro3",
            tituloComponente: "Filtro Select",
        },
        {
            tipoComponente: 4,
            tipoPlantilla: 6,
            nombreArchivo: "Filtro4",
            tituloComponente: "Filtro MultiSelect",
        },
    ],
};
/*Ejemplo de Dashboard*/
export function Ejemplo() {
    descargarArchivosDashboardZip(dashboard.nombre, dashboard.componentes);
}

function procesaCaracteresEspeciales(str) {
    //Nombre Capitalizado
    const strCapitalized = str.charAt(0).toUpperCase() + str.slice(1)
    //Eliminacion de Espacios 
    return strCapitalized.replace(/[^a-zA-Z0-9]/g, "");
}
function toMatrix(arr, width) {
    return arr.reduce(function (rows, key, index) {
        return (index % width == 0 ? rows.push([key]) : rows[rows.length - 1].push(key)) && rows;
    }, []);
}

function crearPlantilla(tipo, nombreArchivo, tituloComponente) {
    let tituloComponenteTrim = tituloComponente ? tituloComponente.replace(/\s+/g, "") : "";
    let plantilla = null;

    //Plantillas
    switch (tipo) {
        case tipoPlantilla.GraficaBarra:
            plantilla = graficaBase;
            plantilla = plantilla
                .replace(tokenImportsGrafSunburst, "")
                .replace(tokenImportsGrafMap, "")
                .replace(tokenGraficas, graficaBarras)
                .replace(tokenHCImpContructorType, "chart");
            break;
        case tipoPlantilla.GraficaCircular:
            plantilla = graficaBase;
            plantilla = plantilla
                .replace(tokenImportsGrafSunburst, "")
                .replace(tokenImportsGrafMap, "")
                .replace(tokenGraficas, graficaCircular)
                .replace(tokenHCImpContructorType, "chart");
            break;
        case tipoPlantilla.GraficaMixta:
            plantilla = graficaBase;
            plantilla = plantilla
                .replace(tokenImportsGrafSunburst, "")
                .replace(tokenImportsGrafMap, "")
                .replace(tokenGraficas, graficaMixta)
                .replace(tokenHCImpContructorType, "chart");
            break;
        case tipoPlantilla.GraficaHotMap:
            plantilla = graficaBase;
            plantilla = plantilla
                .replace(tokenImportsGrafSunburst, "")
                .replace(tokenImportsGrafMap, importMap)
                .replace(tokenGraficas, graficaHotMap)
                .replace(tokenHCImpContructorType, "mapChart");
            break;
        case tipoPlantilla.GraficaSunburst:
            plantilla = graficaBase;
            plantilla = plantilla
                .replace(tokenImportsGrafSunburst, importSunburts)
                .replace(tokenImportsGrafMap, "")
                .replace(tokenGraficas, graficaSunburst)
                .replace(tokenHCImpContructorType, "chart");
            break;
        case tipoPlantilla.IndicadorSimple:
            plantilla = indicadorSimple;
            break;
        case tipoPlantilla.IndicadorDoble:
            plantilla = indicadorDoble;
            break;
        case tipoPlantilla.FiltroBoton:
            plantilla = filtroBoton;
            break;
        case tipoPlantilla.FiltroCheckBox:
            plantilla = filtroCheckBox;
            break;
        case tipoPlantilla.FiltroMultiselect:
            plantilla = filtroMultiSelect;
            break;
        case tipoPlantilla.FiltroSelect:
            plantilla = filtroSelect;
            break;
    }
    if (plantilla) {
        plantilla = plantilla
            .replace(tokenMetodosDeControl, metodosControl)
            .replace(tokenImplementacionTickets, ticketImplementacion)
            .replace(tokenPopupDetalle, popUpDetalle)
            .replace(tokenNombreComponente, procesaCaracteresEspeciales(nombreArchivo))
            .replace(tokenDetalleComponente, tituloComponente)
            .replace(tokenDetalleComponenteTrim, tituloComponenteTrim);
    } else {
        plantilla = "DLABS - Error al generar plantilla ";
    }
    return plantilla;
}

function conectarComponentesConPadre(nombreDashBoard, componentes) {
    let plantilla = principal,
        numIndicadoresXRow = 4;
    let indicadoresSimples = [],
        graficas = [],
        filtros = [],
        funcioneFiltros = [];

    //Crear Imports
    let imports = "";
    componentes.forEach((item) => {
        imports += `import ${procesaCaracteresEspeciales(item.nombreArchivo)} from "./${procesaCaracteresEspeciales(
            item.nombreArchivo
        )}";\n`;
    });

    //Creacion de conexiones
    componentes.forEach((item, index) => {
        switch (item.tipoComponente) {
            case tipoComponente.Grafica: {
                let html = `<div className="col-md-6">
                {/* Gráfica - ${item.tituloComponente} */}
                <${procesaCaracteresEspeciales(item.nombreArchivo)}
                    parametro1={this.state.parametro1}
                    parametro2={this.state.parametro2}
                    parametroN={this.state.parametroN}
                />
                </div>`;

                graficas.push(html);
                break;
            }
            case tipoComponente.Indicador: {
                let html = `<div className={"col-md-${12 / numIndicadoresXRow}"} key={"${procesaCaracteresEspeciales(
                    item.tituloComponente
                )}${index}"}>
                {/* Indicador - ${item.tituloComponente} */}
                <${procesaCaracteresEspeciales(item.nombreArchivo)} 
                                parametro1={this.state.parametro1}
                                parametro2={this.state.parametro2}
                                parametroN={this.state.parametroN}
                            /> </div>`;

                indicadoresSimples.push(html);
                break;
            }
            case tipoComponente.IndicadorDoble: {
                let html = `<div className="col-md-6">
                {/* Indicador - ${item.tituloComponente} */}
                <${procesaCaracteresEspeciales(item.nombreArchivo)} 
                                parametro1={this.state.parametro1}
                                parametro2={this.state.parametro2}
                                parametroN={this.state.parametroN}
                            />
                </div>`;

                graficas.push(html);
                break;
            }
            case tipoComponente.Filtro: {
                let html = `{/* Filtro - ${item.tituloComponente} */}
                <${procesaCaracteresEspeciales(
                    item.nombreArchivo
                )} onSelectCallBack={this.onSelect${procesaCaracteresEspeciales(item.nombreArchivo)}} />`;
                let fnc = `onSelect${procesaCaracteresEspeciales(item.nombreArchivo)} = (selection) => {
                            if (selection != null) {}
                            else {}
                            };`;

                filtros.push(html);
                funcioneFiltros.push(fnc);
                break;
            }
        }
    });

    //Distribucion
    let graficaRows = "",
        indicadorRows = "",
        sfiltros = "",
        sfuncionesFiltros = "";

    //Filtros
    filtros.forEach((item) => {
        sfiltros += item;
    });

    //Metodos Filtros
    funcioneFiltros.forEach((item) => {
        sfuncionesFiltros += item;
    });

    //Graficas e Indicadores dobles
    let tempGraficas = toMatrix(graficas, 2);
    tempGraficas.forEach((item) => {
        graficaRows += `<div className="row">${item}</div>`;
    });

    //Indicadores
    let tempIndicadores = toMatrix(indicadoresSimples, numIndicadoresXRow);
    tempIndicadores.forEach((item, index) => {
        indicadorRows += `<div className="row"> ${item}</div>`;
    });

    plantilla = plantilla
        .replace(tokenImportsPrincipal, imports)
        .replace(tokenNombreDashboard, procesaCaracteresEspeciales(nombreDashBoard))
        .replace(tokenCallbacksFiltros, sfuncionesFiltros.replace(/,/gi, ""))
        .replace(tokenFiltros, sfiltros.replace(/,/gi, ""))
        .replace(tokenIndicadores, indicadorRows.replace(/,/gi, ""))
        .replace(tokenGraficas, graficaRows.replace(/,/gi, ""));

    return plantilla;
}
function conectarPadreConDashboardsMenu(nombreDashBoard) {
    let plantilla = dashboards;
    plantilla = plantilla.replace(tokenNombreDashboard, procesaCaracteresEspeciales(nombreDashBoard));

    return plantilla;
}
export function descargarArchivosDashboardZip(nombreDashBoard, componentes) {
    var zip = new JSZip();

    var dashboardFolder = zip.folder(procesaCaracteresEspeciales(nombreDashBoard));

    //Se hace la conexion de los nuevoscomponentes con su componente padre
    let plantillaPadre = conectarComponentesConPadre(nombreDashBoard, componentes);

    //Se conecta con dashboard /Menu
    let dashboards = conectarPadreConDashboardsMenu(nombreDashBoard);

    //Creacion de archivos
    if (componentes.length) {
        componentes.forEach((item) => {
            let plantilla = crearPlantilla(item.tipoPlantilla, item.nombreArchivo, item.tituloComponente);
            let archivo = new File([plantilla], procesaCaracteresEspeciales(item.nombreArchivo) + ".js", {
                type: "text/javascript;charset=utf-8",
            });
            dashboardFolder.file(procesaCaracteresEspeciales(item.nombreArchivo) + ".js", archivo, { base64: false });
        });
    }

    //Creacion de la platilla Padre
    let archivoPadre = new File([plantillaPadre], procesaCaracteresEspeciales(nombreDashBoard) + ".js", {
        type: "text/javascript;charset=utf-8",
    });

    //Creacion de la platilla Dashboards/Menu
    let archivoDashboards = new File([dashboards], "dashboards.js", {
        type: "text/javascript;charset=utf-8",
    });

    dashboardFolder.file(procesaCaracteresEspeciales(nombreDashBoard) + ".js", archivoPadre, { base64: false });
    zip.file("dashboards.js", archivoDashboards, { base64: false });

    zip.generateAsync({ type: "blob" }).then(function (content) {
        FileSaver.saveAs(content, procesaCaracteresEspeciales(nombreDashBoard) + ".zip");
    });
}
