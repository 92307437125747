import React from "react";
import DataGrid, {
  Column,
  Editing,
  Popup,
  FilterRow,
  Paging,
  Texts,
  Lookup,
  RequiredRule,
} from "devextreme-react/data-grid";
import { TagBox } from "devextreme-react/tag-box";
import SelectBox from "devextreme-react/select-box";
import { Card, CardHeader, CardBody, CardTitle, Row, Col } from "reactstrap";
import { config } from "../../utils/config";
import { callApi, showSweetAlert } from "../../utils/utils";
import ArrayStore from "devextreme/data/array_store";

class Paginas extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paginas: [],
      subProductos: [],
      perfiles: new ArrayStore({
        data: [],
        key: "claPerfil",
      }),
      perfilesLookup: null,
      subProductoSelected: null,
    };

    //Se asocia el metodo o evento al componente
    this.onRowInserted = this.onRowInserted.bind(this);
    this.onRowUpdated = this.onRowUpdated.bind(this);
    this.onRowRemoved = this.onRowRemoved.bind(this);
    this.onSelectSubProducto = this.onSelectSubProducto.bind(this);
    this.tagPefilesEditorRender = this.tagPefilesEditorRender.bind(this);
  }

  onSelectSubProducto(data) {
    this.setState({
      subProductoSelected: data.value,
    });
    this.getPaginas();
  }

  /**
   * Metodo para obtener los paginas
   */
  getPaginas = () => {
    let claSubProducto = this.state.subProductoSelected.claSubProducto;
    //Obtener Paginas
    const urlPaginas = config.urlKiwiAPI + "pagina/obtenerPaginas/" + claSubProducto.toString();
    callApi(urlPaginas, "GET", null, (res) => {
      this.setState({ paginas: res.data });
    });
  };
  /**
   * Metodo para obtener los perfiles
   */
  getPerfiles = () => {
    //Obtener Perfiles
    const urlPerfiles = config.urlKiwiAPI + "usuarios/usuariosTiposPerfiles";
    callApi(urlPerfiles, "GET", null, (res) => {
      let perfiles = new ArrayStore({
        data: res.data,
        key: "claPerfil",
      });
      this.setState({ perfiles: perfiles, perfilesLookup: res.data });
    });
  };

  /**
   * Metodo para obtener las SubProductos
   */
  getSubProductos = () => {
    //Obtener SubProductos
    const urlSubProductos = config.urlKiwiAPI + "usuarios/usuariosObtenerSubProductos";
    callApi(urlSubProductos, "GET", null, (res) => {
      this.setState({ subProductos: res.data });
    });
  };
  /**
   * Metodo para Insertar nuevos registros
   * @param {Object} obj contiene el objeto contenido {data} en el Row con los valores modificados
   */
  onRowInserted(obj) {
    if (obj.data) {
      //Parametros o cuerpo del servicio
      let body = {
        nomPagina: obj.data.nomPagina,
        ruta: obj.data.ruta,
        icono: obj.data.icono,
        color: "text-primary",
        orden: obj.data.orden,
        indicador: obj.data.indicador,
        componente: obj.data.componente,
        claSubProducto: this.state.subProductoSelected.claSubProducto,
        claPerfil: obj.data.claPerfil,
      };

      //Insertar Pagina
      const urlInsertarPag = config.urlKiwiAPI + "pagina/agregarPagina";
      callApi(urlInsertarPag, "POST", body, (res) => {
        //Se insertop la informacion correctamente
        showSweetAlert("Operación Correcta", "Se guardó la información correctamente", "success");
        this.getPaginas();
      });
    }
  }
  /**
   * Metodo para Actualizar nuevos registros
   * @param {Object} obj contiene el objeto contenido {data} en el Row con los valores modificados
   */
  onRowUpdated(obj) {
    this.updatePagina(obj);
  }
  /**
   * Funcion que se ejecuta al dar de baja un usuario
   * @param {Obj} e objeto del row que se esta dando de baja
   */
  onRowRemoved(obj) {
    this.updatePagina(obj, true);
  }
  updatePagina = (obj, remove = false) => {
    if (obj.data) {
      //Parametros o cuerpo del servicio
      let body = {
        nomPagina: obj.data.nomPagina,
        ruta: obj.data.ruta,
        icono: obj.data.icono,
        color: "text-primary",
        orden: obj.data.orden,
        indicador: obj.data.indicador,
        componente: obj.data.componente,
        bajaLogica: remove ? 1 : 0,
        claSubProducto: this.state.subProductoSelected.claSubProducto,
        claPerfil: obj.data.claPerfil,
      };

      //Actualizar Pagina
      const urlActualizaPag = config.urlKiwiAPI + "pagina/actualizarPagina/" + obj.data.claPagina;
      callApi(urlActualizaPag, "PUT", body, (res) => {
        //Actualizo la informacion correctamente
        showSweetAlert("Operación Correcta", "Se actualizó la información correctamente", "success");
        this.getPaginas();
      });
    }
  };
  componentDidMount() {
    //Obtener Paginas
    this.getPerfiles();
    this.getSubProductos();
  }
  onValueChanged(cell, e) {
    cell.setValue(e.value);
  }
  tagPefilesEditorRender(cell) {
    let onValueChanged = this.onValueChanged.bind(this, cell);
    return (
      <TagBox
        hideSelectedItems={true}
        dataSource={this.state.perfiles}
        displayExpr="nomPerfil"
        valueExpr="claPerfil"
        onValueChanged={onValueChanged}
        {...cell.column.lookup}
        defaultValue={cell.value}
      />
    );
  }
  render() {
    const GridContent = (
      <DataGrid
        id="gridContenedor"
        dataSource={this.state.paginas}
        keyExpr="claPagina"
        showBorders={false}
        columnHidingEnabled={true}
        columnAutoWidth={false}
        showColumnHeaders={true}
        showColumnLines={false}
        showRowLines={true}
        noDataText={"Sin Registros"}
        wordWrapEnabled={true}
        rowAlternationEnabled={false}
        onRowInserted={this.onRowInserted}
        onRowUpdated={this.onRowUpdated}
        onRowRemoved={this.onRowRemoved}
      >
        <FilterRow visible={true} showAllText={"Todos"} />
        <Paging enabled={true} pageSize={15} />

        <Editing mode="popup" allowUpdating={true} allowDeleting={true} allowAdding={true}>
          <Popup title="Crear/Editar Página" showTitle={true} width={700} height={600} />
          <Texts saveRowChanges="Guardar" cancelRowChanges="Cancelar" />
        </Editing>

        <Column dataField="nomPagina" caption="Nombre" minWidth={150}>
          <RequiredRule />
        </Column>
        <Column dataField="ruta" caption="Ruta" minWidth={150}>
          <RequiredRule />
        </Column>
        <Column dataField="icono" caption="Icono" minWidth={100} visible={false}>
          <RequiredRule />
        </Column>
        <Column dataField="orden" caption="Orden" width={80}>
          <RequiredRule />
        </Column>
        <Column dataField="indicador" caption="Indicador" width={100}>
          <RequiredRule />
        </Column>
        <Column dataField="componente" caption="Dashboard" minWidth={150}>
          <RequiredRule />
        </Column>
        <Column
          dataField="claPerfil"
          caption="Perfil"
          visible={false}
          width={200}
          editCellRender={this.tagPefilesEditorRender}
        >
          <Lookup dataSource={this.state.perfilesLookup} displayExpr="nomPerfil" valueExpr="claPerfil" />
          <RequiredRule />
        </Column>
      </DataGrid>
    );

    const Filtros = () => {
      return <div>{GridContent}</div>;
    };

    return (
      <>
        <div className="content">
          <Card>
            <CardHeader>
              <Row>
                <Col>
                  <CardTitle tag="h4">Configuración de Páginas</CardTitle>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Row className="align-items-start">
                <Col md={3}>
                  <SelectBox
                    dataSource={this.state.subProductos}
                    searchEnabled={true}
                    noDataText="No hay SubProductos"
                    placeholder="Seleccionar un SubProducto"
                    displayExpr="nomSubProducto"
                    displayValue="claSubProducto"
                    value={this.state.subProductoSelected}
                    onValueChanged={this.onSelectSubProducto}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Filtros />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      </>
    );
  }
}
export default Paginas;
