export const graficaHotMap = `

/*Metodo de carga de datos*/
fillData() {

    showLoad();

    let krakenParams = {
        ClaProducto: 3,
        IdEntidad: 6,
        TipoEstructura: 1,
        ParamsSP: null,
        Callback: null,
    };

    //Parametros que recibe el servicio
    krakenParams.ParamsSP = {
        ClaTipoComponente: 1,
        ClaTipoPlantilla:10
    };

    krakenParams.Callback = (response) => {
     
        /*Aqui va el proceso de los datos devueltos por la entidad
        Documentacion de ayuda:  https://www.highcharts.com/demo/maps/all-maps */

        let grafica = this.getChartOptions();
    
        let listaValores = [] ;

        response.Result0.forEach((elemento)=>{
            let dato =[];
             dato[0] = elemento.Ciudad;
             dato[1] = elemento.Valor;
             listaValores.push(dato);
        });

    
        grafica.series[0].data = listaValores;
       
        this.setState({
            options: grafica,
            detalle: response.Result1,
        });
    
        /*Instruccion Usada para resizear la grafica a su contenedor*/
        this.graficaRef.current.chart.reflow();

        hideLoad();
    };

    /*Hacemos la peticion para el servicio el indicador*/
    callKrakenApi(
        krakenParams.ClaProducto,
        krakenParams.IdEntidad,
        krakenParams.ParamsSP,
        krakenParams.TipoEstructura,
        krakenParams.Callback
    );
}
getChartOptions() {
   return {
    chart: {
        map: mapDataMexico,
    },
    title: {
        text: "",
    },
    mapNavigation: {
        enabled: true,
        buttonOptions: {
            verticalAlign: "bottom",
        },
    },
    credits: {
        enabled: false,
    },
    exporting: {
        enabled: false,
    },
    colorAxis: {
        min: 0,
        minColor: "#e9f9ed",
        maxColor: "#24973e",
    },
    plotOptions: {
        series: {
            events: {
                click: (e) => {
                    var nombre = e.point.options["hc-key"];
                    alert(nombre);
                },
            },
        },
    },
    series: [
        {
            data: null,
            name: "Toneladas",
            cursor: "pointer",
            states: {
                hover: {
                    color: "#e6e7ec",
                },
            },
            dataLabels: {
                enabled: true,
                format: "{point.name}",
            },
        },
    ],
};
}`;
