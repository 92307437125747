export const filtroBoton = `import React, { Component } from "react";
import { hideLoad, callKrakenApi, showLoad } from "../../utils/utils";
import { config } from "../../utils/config";

class #Nombre extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }
    getData() {
        showLoad();

        let krakenParams = {
            ClaProducto: 3,
            IdEntidad: 6,
            TipoEstructura: 1,
            ParamsSP: null,
            Callback: null,
        };

        //Parametros que recibe el servicio
        krakenParams.ParamsSP = {
            ClaTipoComponente: 4,
            ClaTipoPlantilla:3
        };

        krakenParams.Callback = (response) => {
            //Metodo de regreso de informacion
            if (this.props.onSelectCallBack) this.props.onSelectCallBack(response);

            hideLoad();
        };

        //Hacemos la peticion para el servicio el indicador
        callKrakenApi(
            krakenParams.ClaProducto,
            krakenParams.IdEntidad,
            krakenParams.ParamsSP,
            krakenParams.TipoEstructura,
            krakenParams.Callback
        );
    }
    clickBtn = () => {
        this.getData();
    };
    componentDidMount() {}
    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
        }
    }
    render() {
        return (
            <div id="btn#TDX" className="no-border btn-actualizar">
                <button className="btn btn-primary btn-round float-right" onClick={this.clickBtn}>
                    #TD
                </button>
            </div>
        );
    }
}

export default #Nombre ;`;
