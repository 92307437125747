export const principal = `
import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import {metricaAccion} from "../../utils/metrica";
#ImpCNT

class #NombreDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            parametro1: 0,
            parametro2: "", 
            parametroN: 0,
            ubicaciones: "",
            esToneladas: 0,
           
        };
    }
    
    //Metodos callback de componentes tipo filtro
    #ImpMFLT
   
    componentDidMount() {
        //Nombre del DashBoard
        document.getElementById("nombreDashboard").innerText = "#NombreDashboard";

        /*Implementación de Métricas
        Este codigo es utilizado con fines estadisticos y de medicion, por favor no borrar la implementación, 
        en su defecto colocarla a criterio según sea el caso  */
         metricaAccion(window.location.href,"Entro a #NombreDashBoard ");
    }
    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
            
        }
    }
    render() {
        //Filtros 
        const filtrosC = (
            <Row id="filtrosDashBoard">
                <Col md="12">
                    <div className={"d-flex float-md-right  align-items-center"}>
                        #ImpFLT
                    </div>
                </Col>
            </Row>
        );

        return (
            <div className="content">
                {/* FILTROS*/}
                {filtrosC}
                {/* Indicadores */}
                <div className="row" key={"Indicadores"}>
                    <div className="col-md-12">
                        <div className="card card-stats">
                            <div className="card-body">
                                   #ImpIND
                            </div>
                        </div>
                    </div>
                </div> 
                #ImpGRF
            </div>
        );
    }
}

export default #NombreDashboard;
`;
