import { callApi} from "../utils/utils";
import { config } from "../utils/config";


export function metricaAccion(urlPagina, accion) {
    let urlService = config.urlKiwiAPI + "Metrica/Accion";

    let params = {
      UrlPagina:urlPagina,
      Accion:accion
    };

    callApi(urlService, "POST", params ,(response)=>{
      //En esta ocacion no hay retorno, ya que invocamos un servicio de insercion
    });

  }
