export const indicadorSimple = `import { Popup, ToolbarItem } from "devextreme-react/popup";
import React, { Component } from "react";
import ExcelJS from "exceljs";
import FileSaver from "file-saver";
import { showLoad, hideLoad, callKrakenApi } from "../../utils/utils";
import DataGrid, { Export, MasterDetail } from "devextreme-react/data-grid";
import { exportDataGrid } from "devextreme/excel_exporter";
import Ticket from "../../components/core/Ticket";
import { renderExportButton } from "../../components/core/ExportExcelGrid";
import {metricaAccion} from "../../utils/metrica";

class #Nombre extends Component {
    constructor(props) {
        super(props);
        this.state = {
            indicador: "0",
            popupDetalle: false,
            detalle: null,
            popupTicketVisible: false,
            popupTicketDetalleVisible: false,
        };
    }

    /* Metodos de Control */
    #ImpMC

    onClickPopupDetalle = () => {
        /*Implementación de Métricas
        Este codigo es utilizado con fines estadisticos y de medicion, por favor no borrar la implementación, 
        en su defecto colocarla a criterio según sea el caso  */
         metricaAccion(window.location.href,"Click a detalle en componente #Nombre ");

        this.setState({ popupDetalle: true });

        //Pasando Parametros desde un componente padre (this.props)
        //this.getData(this.props.claUbicacion, this.props.segmentos);

        //Sin Pasar Parametros
        this.getData();
    };
    hidePopUpDetalle = () => {
        this.setState({ popupDetalle: false });
    };

    //Pasando Parametros desde un componente padre (this.props)
    //getData(parametro1, parametro2, parametro3) {
    //Sin pasar parametros
     getData(){
        showLoad();

        let krakenParams = {
            ClaProducto: 3,
            IdEntidad: 6,
            TipoEstructura: 1,
            ParamsSP: null,
            Callback: null,
        };

        //Parametros de la entidad
        krakenParams.ParamsSP = { 
            ClaTipoComponente: 2,
            ClaTipoPlantilla:2
        };

        krakenParams.Callback = (response) => {

            this.setState({
                indicador: response.Result0[0].NombreIndicador,
                 detalle: response.Result1,
            });

            hideLoad();
        };

        //Hacemos la peticion para el servicio el indicador
        callKrakenApi(
            krakenParams.ClaProducto,
            krakenParams.IdEntidad,
            krakenParams.ParamsSP,
            krakenParams.TipoEstructura,
            krakenParams.Callback
        );
    }
    onExporting(e) {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Main sheet");

        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
            autoFilterEnabled: true,
        }).then(function () {
            workbook.xlsx.writeBuffer().then(function (buffer) {
                FileSaver(new Blob([buffer], { type: "application/octet-stream" }), "DataGrid.xlsx");
            });
        });
        e.cancel = true;
    }
    
    componentDidMount() {

        //Pasando Parametros desde un componente padre (this.props)
        //this.getData(this.props.parametro1, this.props.parametro2, this.props.parametro3);

        //Sin pasar parametros
        this.getData();
        
    }
    componentDidUpdate(prevProps) {
        //Actualizacion del componente
        if (this.props !== prevProps) {

            //Pasando Parametros desde un componente padre (this.props)
            //this.getData(this.props.parametro1, this.props.parametro2, this.props.parametro3);

            //Sin pasar parametros
            this.getData();
        }
    }
    render() {
        
        /* Implementacion de Tickets(Levantamiento de Incidentes) */
        #ImpTKT
    
        /* Implementacion de PopUp Detalle */
        #ImpPD

        return (
            <div className="statistics">
                <div className="info">
                    <div  className="indicadores-dashboard" onClick={this.onClickPopupDetalle}>
                        <div className="ico-indicator">
                            <i className="fas fa-dumpster-fire" style={{ color: "#f96332" }}></i>
                        </div>
                        <div>
                            <h6 className="stats-title">Nombre Indicador</h6>
                            <h5 className="indicator">{this.state.indicador}</h5>
                        </div>
                        {IconoTicket}
                    </div>
                </div>

                {Tickets}
                {PopUpDetalle}
            </div>
        );
    }
}

export default #Nombre;`;
