export const filtroSelect = `
import React, { Component } from "react";
import SelectBox from "devextreme-react/select-box";
import { config } from "../../utils/config";
import { callKrakenApi } from "../../utils/utils";

class #Nombre extends Component {
    constructor(props) {
        super(props);

        this.state = {
            comboBoxDefaultValue: 1,
            dataSource: null,
        };
        this.syncComboSelection = this.syncComboSelection.bind(this);
    }
    getData() {
        let krakenParams = {
            ClaProducto: 3,
            IdEntidad: 6,
            TipoEstructura: 1,
            ParamsSP: null,
            Callback: null,
        };

        //Parametros que recibe el servicio
        krakenParams.ParamsSP = {
            ClaTipoComponente: 4,
            ClaTipoPlantilla:5
        };
        
        krakenParams.Callback = (response) => {
            this.setState({ dataSource: response.Result0 });
        };

        //Hacemos la peticion para el servicio
        callKrakenApi(
            krakenParams.ClaProducto,
            krakenParams.IdEntidad,
            krakenParams.ParamsSP,
            krakenParams.TipoEstructura,
            krakenParams.Callback
        );
    }
    syncComboSelection(e) {
        if (e.value !== undefined) {
            this.setState({
                comboBoxValue: e.value,
            });

            if (this.props.onSelectCallBack) this.props.onSelectCallBack(this.state.comboBoxValue);
        } else {
            if (this.props.onSelectCallBack) this.props.onSelectCallBack(null);
        }
    }
    componentDidMount() {
        this.getData();
    }
    render() {
        return (
            <div style={{ marginLeft: "5px", marginRight: "5px" }}>
                <SelectBox
                    dataSource={this.state.dataSource}
                    width={250}
                    value={this.state.comboBoxValue}
                    valueExpr={"Clave"}
                    displayExpr={"Nombre"}
                    placeholder={"Selecciona Algo"}
                    showClearButton={false}
                    onValueChanged={this.syncComboSelection}
                />
            </div>
        );
    }
}

export default #Nombre ;`;
