export const indicadorDoble =`import { Popup, ToolbarItem } from "devextreme-react/popup";
import React, { Component } from "react";
import ExcelJS from "exceljs";
import FileSaver from "file-saver";
import { showLoad, hideLoad, callKrakenApi } from "../../utils/utils";
import DataGrid, { Export, MasterDetail } from "devextreme-react/data-grid";
import { exportDataGrid } from "devextreme/excel_exporter";
import Ticket from "../../components/core/Ticket";
import { renderExportButton } from "../../components/core/ExportExcelGrid";
import {metricaAccion} from "../../utils/metrica";

class #Nombre extends Component {
    constructor(props) {
        super(props);
        this.state = {
            indicador1: "0",
            indicador2:"0",
            detalle:null,
            popupDetalle: false,
            popupTicketVisible: false,
            popupTicketDetalleVisible: false,
        };
    }

    /* Metodos de Control */
    #ImpMC

    hidePopUpDetalle = () => {
        this.setState({ popupDetalle: false });
    };
    onClickPopupDetalle = () => {
        /*Implementación de Métricas
        Este codigo es utilizado con fines estadisticos y de medicion, por favor no borrar la implementación, 
        en su defecto colocarla a criterio según sea el caso  */
         metricaAccion(window.location.href,"Click a detalle en componente #Nombre ");

        this.setState({ popupDetalle: true });
    };

    //Pasando Parametros desde un componente padre (this.props)
    //getData(parametro1, parametro2, parametro3) {}
    //Sin pasar parametros
     getData(){
        showLoad();

        let krakenParams = {
            ClaProducto: 3,
            IdEntidad: 6,
            TipoEstructura: 1,
            ParamsSP: null,
            Callback: null,
        };

        //Parametros que recibe el servicio
        krakenParams.ParamsSP = {
            ClaTipoComponente: 3,
            ClaTipoPlantilla:7
        };

        krakenParams.Callback = (response) => {
          
             this.setState({
                 indicador1: response.Result0[0].NombreIndicador1,
                 indicador2: response.Result0[0].NombreIndicador2,
                    detalle: response.Result1,
             });

            hideLoad();
        };

        //Hacemos la peticion para el servicio el indicador
        callKrakenApi(
            krakenParams.ClaProducto,
            krakenParams.IdEntidad,
            krakenParams.ParamsSP,
            krakenParams.TipoEstructura,
            krakenParams.Callback
        );
    }
    onExporting(e) {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Main sheet");

        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
            autoFilterEnabled: true,
        }).then(function () {
            workbook.xlsx.writeBuffer().then(function (buffer) {
                FileSaver(new Blob([buffer], { type: "application/octet-stream" }), "DataGrid.xlsx");
            });
        });
        e.cancel = true;
    }
    
    componentDidMount() {

        //Pasando Parametros desde un componente padre (this.props)
        //this.getData(this.props.parametro1, this.props.parametro2, this.props.parametro3);

        //Sin pasar parametros
        this.getData();
        
    }
    componentDidUpdate(prevProps) {
        //Actualizacion del componente
        if (this.props !== prevProps) {

            //Pasando Parametros desde un componente padre (this.props)
            //this.getData(this.props.parametro1, this.props.parametro2, this.props.parametro3);

            //Sin pasar parametros
            this.getData();
        }
    }
    render() {
        
        /* Implementacion de Tickets(Levantamiento de Incidentes) */
        #ImpTKT
    
        /* Implementacion de PopUp Detalle */
        #ImpPD

        return (
            <div className="card card-pricing " style={{ height: "189px" }}>
            <div className="card-header d-flex flex-row">
                <div className="graf_tickets" style={{ display: "inline-flex", margin: "0px", padding: "0px" }}>
                    <h6  className="card-title">
                       Nombre Indicador
                    </h6>
                    {IconoTicket}
                    {PopUpDetalle}
                    {Tickets}
                </div>
            </div>
            <div className="card-body">
                <div className="row mt-n4">
                    <div className="col-6">
                        <h3  style={{ fontWeight: "700" }}>
                            {this.state.indicador1}
                        </h3>
                    </div>
                    <div className="col-6">
                        <h3  style={{ fontWeight: "700" }}>
                            {this.state.indicador2}
                        </h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <label className="cardLabelIndicatorD">Indicador 1</label>
                    </div>
                    <div className="col-6">
                        <label className="cardLabelIndicatorD">Indicador 2</label>
                    </div>
                </div>
            </div>
            <div className="card-footer">
                <div className="row">
                    <div className="col-6">
                        <div className="no-border d-flex justify-content-end" >
                            <button className="btn btn-primary btn-round " onClick={this.onClickPopupDetalle} >Ver Detalle 1</button>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="no-border d-flex justify-content-start"
                        >
                            <button className="btn btn-primary btn-round " onClick={this.onClickPopupDetalle} >Ver Detalle 2</button>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        );
    }
}

export default #Nombre;`;