export const dashboards = `
/* Importar los dashboards generados
Ejemplo:
import DashboardNuevo from "./views/DasboardNuevo/DasboardNuevo";
*/
import #NombreDashboard from "./views/#NombreDashboard/#NombreDashboard";

export const Dashboards = [
    {
        name: "#NombreDashboard",
        component: #NombreDashboard,
    },
    /* Agregar aqui los dashbords conforme se vallan creando
        Ejemplo:  
        {
            name: "DashboardNuevo",
            component: DashboardNuevo,
        }, ...
    */
];`;