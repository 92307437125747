import React, { Component } from "react";
import { Button, Card, CardHeader, CardBody, Row, Col } from "reactstrap";
import Scrollbar from "perfect-scrollbar";
import { Popup } from "devextreme-react/popup";
import parse from "html-react-parser";
import ScrollView from "devextreme-react/scroll-view";
import { callApi } from "@tlugo-deacero/dlabs-utils";
import { config } from "../../utils/config";
import SelectBox from "devextreme-react/select-box";
import swal from "sweetalert";

class ListaAlertas extends Component {
    constructor(props) {
        super(props);

        this.state = {
            paginaActual: 1,
            alertasPorPagina: 5,
            popupVisible: false,
            indexSelected: null,
            alertaSelected: null,
            acciones: [
                // { idAccion: 1, descripción: "Avisar a encargado embarque" },
                // { idAccion: 2, descripción: "Quitar del plan de carga" },
                // { idAccion: 3, descripción: "Retornar al almacen" },
            ],
            accionSelected: null,
        };

        this.mainAlerts = React.createRef();
        this.ps = null;
        this.onShowPopUp = this.onShowPopUp.bind(this);
        this.onClosePopUp = this.onClosePopUp.bind(this);
        this.renderModal = this.renderModal.bind(this);
        this.renderAccion = this.renderAccion.bind(this);
        this.getAccion = this.getAccion.bind(this);
        this.onSelectAccion = this.onSelectAccion.bind(this);
        this.setAccion = this.setAccion.bind(this);
    }

    setAccion(event) {
        const alerta = this.state.alertaSelected;
        const tipoUpdate = this.state.accionSelected ? 2 : 1;
        const urlWebService = config.urlKiwiAPI + "Alert/SetAction/" + alerta.idAlerta;

        const params = {
            idBitacora: alerta.idBitacora,
            IdAlerta: alerta.idAlerta,
            TipoUpdate: tipoUpdate,
            TipoAlerta: alerta.tipo,
        };

        if (tipoUpdate === 2) {
            params.IdAccion = this.state.accionSelected;
        }

        callApi(config, urlWebService, "POST", params).then((res) => {
            if (tipoUpdate === 2) {
                swal("Solución enviada", "¡La Alerta ha sido solucionada correctamente!", "success").then((value) => {
                    this.onClosePopUp();
                });
            }
        });
    }

    onSelectAccion(data) {
        this.setState({
            accionSelected: data.value,
        });
    }

    getAccion(alerta) {
        const urlWebService = config.urlKiwiAPI + "Alert/GetActions/" + alerta.idAlerta;

        callApi(config, urlWebService, "GET", null).then((res) => {
            this.setState({ acciones: res.data });
        });
    }

    onShowPopUp(index) {
        const alerta = this.props.alertas[index];

        const urlWebService = config.urlKiwiAPI + "Alert/GetDetail/" + alerta.idBitacora;

        callApi(config, urlWebService, "GET", null).then((res) => {
            this.setState({ popupVisible: true, indexSelected: index, alertaSelected: res.data });
        });

        this.getAccion(alerta);
    }

    onClosePopUp() {
        this.setState({
            popupVisible: false,
            indexSelected: null,
            alertaSelected: null,
            acciones: [],
            accionSelected: null,
        });
    }

    componentDidMount() {
        if (this.mainAlerts.current) {
            this.ps = new Scrollbar(this.mainAlerts.current);
            this.ps.settings.suppressScrollX = true;
        }
    }

    componentWillUnmount() {
        if (this.ps) {
            this.ps.destroy();
            this.ps = null;
        }
    }
    componentDidUpdate() {
        this.ps.update(this.mainAlerts);
    }

    renderAccion(alerta) {
        if (alerta.claEstatus === 1 && alerta.puedeAccionar === 1) {
            return (
                <Col lg="8">
                    <Row className="mt-4">
                        <Col>
                            <Row>
                                <Col lg="2" className="mt-2 pr-0">
                                    <span
                                        className="text-primary"
                                        style={{ color: "#fa8c31", fontSize: "1em", fontWeight: "bold" }}
                                    >
                                        Solución:
                                    </span>
                                </Col>
                                <Col lg="6">
                                    <SelectBox
                                        dataSource={this.state.acciones}
                                        noDataText="No hay soluciones a tomar"
                                        placeholder="Seleccionar"
                                        valueExpr="idAccion"
                                        displayExpr="descripción"
                                        defaultValue={this.state.accionSelected}
                                        onValueChanged={this.onSelectAccion}
                                        className=""
                                    />
                                </Col>
                                <Col lg="3">
                                    <Button
                                        title="EnviarAccion"
                                        id="EnviarAccion"
                                        size="md"
                                        className="btn btn-primary col-12 font-weight-bold"
                                        onClick={this.setAccion}
                                        style={{ backgroundColor: "#f96332 !important", margin: "0px" }}
                                    >
                                        Enviar
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            );
        }

        return (
            <Col>
                <Row className="mt-4">
                    <Col className="pr-0">
                        <Row>
                            <div className="text-primary text-muted LabelAlerta font-weight-bold">Acción tomada</div>
                        </Row>
                        <Row>
                            <Col className="pl-0">
                                <ScrollView
                                    height="70px"
                                    width="100%"
                                    className="mt-1"
                                    style={{ backgroundColor: "#ececec", borderRadius: "10px" }}
                                >
                                    <div style={{ margin: "5px", padding: "10px" }}>{alerta.solucion}</div>
                                </ScrollView>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        );
    }
    renderModal() {
        // Check to see if there's a selected post. If so, render it.
        if (this.state.alertaSelected) {
            this.setAccion();
            const alerta = this.state.alertaSelected;

            // alerta.responsables = [
            //     { nombre: "Uriel", fechaLeido: "2020/05/10 10:50", fechaSolucion: "2020/05/10 10:50" },
            //     { nombre: "Leiru", fechaLeido: "2020/05/10 07:00", fechaSolucion: null },
            //     { nombre: "Leiru2", fechaLeido: "2020/05/10 07:00", fechaSolucion: null },
            //     { nombre: "Leiru3", fechaLeido: "2020/05/10 07:00", fechaSolucion: null },
            // ];

            if (alerta) {
                const estatusDescripcion = alerta.claEstatus === 1 ? "Pendiente" : "Resuelto";
                const varianteBoton = alerta.claEstatus === 1 ? "BadgePendiente" : "BadgeResuelto";
                const varianteCritica = alerta.esCritico === 1 ? "BadgeCritico" : "BadgeBajo";
                const criticaDescripcion = alerta.esCritico === 1 ? "Crítica" : "Baja";
                const badgeTipo = alerta.tipo === 1 ? "BadgeLectura" : "BadgeAccion";
                const descripcionTarjeta = alerta.tipo === 1 ? "Lectura" : "Requiere Solución";

                const divAccion = this.renderAccion(alerta);

                const responsables = alerta.responsables.map((responsable, index) => {
                    const resaltar = responsable.fechaSolucion === null ? "" : "font-weight-bold";

                    return (
                        <Row className="mt-4 RowFechas" key={index}>
                            <Col lg="4" className="text-center">
                                <div className={`text-muted ${resaltar}`}>{responsable.nombre}</div>
                            </Col>
                            <Col lg="4" className="text-center">
                                <div className={`text-muted ${resaltar}`}>{responsable.fechaLeido}</div>
                            </Col>
                            <Col lg="4" className="text-center">
                                <div className={`text-muted ${resaltar}`}>{responsable.fechaSolucion}</div>
                            </Col>
                        </Row>
                    );
                });

                return (
                    <div className="mr-4 ml-4">
                        <Row>
                            <Col lg="4">
                                <div className="d-flex flex-row align-items-center mt-1">
                                    <span className={`${varianteBoton} mr-1`}>
                                        <i className="fas fa-circle"></i>
                                    </span>
                                    <div className={`BadgeEstatus mr-2`}>{estatusDescripcion}</div>
                                    <div className={`BadgeEstatus ${badgeTipo} mr-2`}>{descripcionTarjeta}</div>
                                    <div className={`BadgeEstatus ${varianteCritica}`}>{criticaDescripcion}</div>
                                </div>
                            </Col>
                            <Col lg="7"></Col>
                            <Col lg="1"></Col>
                        </Row>

                        <div className="ml-5 mr-5">
                            <Row>
                                <Col lg="8">
                                    <Row>
                                        <Col className="text-truncate">
                                            <h4 className="text-muted font-weight-bold" style={{ margin: "10px" }}>
                                                {alerta.titulo}
                                            </h4>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <ScrollView
                                    height="350px"
                                    width="100%"
                                    style={{ backgroundColor: "#ececec", borderRadius: "10px" }}
                                >
                                    <div style={{ margin: "10px" }}>{parse(alerta.htmlFormado)}</div>
                                </ScrollView>
                            </Row>
                            <Row>
                                <Col>
                                    <Row>
                                        <Col className="pr-0 pl-0">
                                            <Row className="mt-4 RowFechas">
                                                <Col lg="4" className="text-center">
                                                    <div className="text-primary text-muted LabelAlerta font-weight-bold">
                                                        Responsables
                                                    </div>
                                                </Col>
                                                <Col lg="4" className="text-center">
                                                    <div className="text-primary text-muted LabelAlerta font-weight-bold">
                                                        Fecha Leido
                                                    </div>
                                                </Col>
                                                <Col lg="4" className="text-center">
                                                    <div className="text-primary text-muted LabelAlerta font-weight-bold">
                                                        Fecha Solución
                                                    </div>
                                                </Col>
                                            </Row>
                                            <ScrollView
                                                height="100px"
                                                width="100%"
                                                className="mt-1"
                                                style={{ backgroundColor: "#ececec", borderRadius: "10px" }}
                                            >
                                                {responsables}
                                            </ScrollView>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="d-flex justify-content-end">{divAccion}</Row>
                        </div>
                    </div>
                );
            }
        }
    }

    render() {
        const { alertasPorPagina } = this.state;
        let Detalle = (
            <Row className="RowDetail">
                <Col className="d-flex flex-row align-items-center justify-content-center SinAlertas">
                    <div className="d-block text-center">
                        <span>
                            <i className="fas fa-kiwi-bird" style={{ fontSize: "5em" }}></i>
                        </span>
                        <h3>Sin Alertas</h3>
                    </div>
                </Col>
            </Row>
        );

        if (this.props.alertas) {
            // Logic for displaying todos
            //const indexUltimaAlerta = this.props.paginaActual * alertasPorPagina;
            //const indexPrimeraAlerta = indexUltimaAlerta - alertasPorPagina;
            const alertasActuales = this.props.alertas; //.slice(indexPrimeraAlerta, indexUltimaAlerta);

            Detalle = alertasActuales.map((alerta, index) => {
                const estatusDescripcion = alerta.claEstatus === 1 ? "Pendiente" : "Resuelto";
                const varianteBoton = alerta.claEstatus === 1 ? "BadgePendiente" : "BadgeResuelto";
                const varianteCritica = alerta.esCritico === 1 ? "BadgeCritico" : "BadgeBajo";
                const criticaDescripcion = alerta.esCritico === 1 ? "Crítica" : "Baja";
                const badgeTipo = alerta.tipo === 1 ? "BadgeLectura" : "BadgeAccion";
                const descripcionTarjeta = alerta.tipo === 1 ? "Lectura" : "Requiere Solución";
                const descripcionFecha = alerta.tipo === 1 ? "Fecha Leído" : "Fecha Solucionado";
                const fecha = alerta.tipo === 1 ? alerta.fechaLeido : alerta.fechaSolucion;
                const iconoLeido = alerta.fechaLeido === null ? "fa-envelope" : "fa-envelope-open-text";
                const textoLeido = alerta.fechaLeido === null ? "Sin Leer" : "Leído";

                return (
                    <Row className="RowDetail" key={index} onClick={() => this.onShowPopUp(index)}>
                        <Card className={`DetailCard mb-3`}>
                            <CardHeader className="CardHeader ml-1">
                                <Row>
                                    <Col lg="4">
                                        <div className="d-flex flex-row align-items-center mt-3">
                                            <span className={`${varianteBoton} mr-1`}>
                                                <i className="fas fa-circle"></i>
                                            </span>
                                            <div className={`BadgeEstatus mr-2`}>{estatusDescripcion}</div>
                                            <div className={`BadgeEstatus ${badgeTipo} mr-2`}>{descripcionTarjeta}</div>
                                            <div className={`BadgeEstatus ${varianteCritica}`}>
                                                {criticaDescripcion}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg="7"></Col>
                                    <Col lg="1">
                                        <div
                                            id={`BotonDetalle${index}`}
                                            title={textoLeido}
                                            className="d-flex flex-row align-items-center mt-3 BotonDetalle"
                                            onClick={() => this.onShowPopUp(index)}
                                        >
                                            <i className={`fas ${iconoLeido}`}></i>
                                        </div>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody className="pt-0">
                                <div className="ml-5 DetalleAlerta">
                                    <Row>
                                        <Col lg="10">
                                            <Row>
                                                <Col className="text-truncate p-0">
                                                    <span className="LabelAlerta text-primary mr-1">Tema:</span>
                                                    <span className="text-muted font-weight-bold">{alerta.titulo}</span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <div className="text-primary mr-2 LabelAlerta">Solución:</div>
                                                <div className="text-muted">{alerta.solucion}</div>
                                            </Row>
                                            {/* <Row>
                                                <Col className="text-truncate">
                                                    <span className="text-primary mr-1 LabelAlerta">Descripción:</span>
                                                    <span className="text-muted DescripcionAlerta">
                                                        {alerta.descripcion}
                                                    </span>
                                                </Col>
                                            </Row> */}
                                        </Col>
                                        <Col lg="4"></Col>
                                    </Row>
                                    <Row className="mt-4 RowFechas">
                                        <Col lg="4" className="text-center">
                                            <div className="text-primary mr-2 LabelAlerta">Solucionado por</div>
                                            <div className="text-muted">{alerta.responsableSolucion}</div>
                                        </Col>
                                        <Col lg="4" className="text-center">
                                            <div className="text-primary text-muted LabelAlerta">Fecha Recibido</div>
                                            <div className="text-muted">{alerta.fechaRecibido}</div>
                                        </Col>
                                        <Col lg="4" className="text-center">
                                            <div className="text-primary text-muted LabelAlerta">
                                                {descripcionFecha}
                                            </div>
                                            <div className="text-muted">{fecha}</div>
                                        </Col>
                                    </Row>
                                </div>
                            </CardBody>
                        </Card>
                    </Row>
                );
            });
        }

        return (
            <div style={{ height: "100vh" }}>
                <div className="col-12 ContentDetail" ref={this.mainAlerts}>
                    {Detalle}
                </div>
                <Popup
                    key={"popUpDetalle"}
                    id={"popUpDetalle"}
                    visible={this.state.popupVisible}
                    onHiding={this.onClosePopUp}
                    onShowing={this.onShowingPopUp}
                    dragEnabled={false}
                    closeOnOutsideClick={true}
                    title="Detalle de Alerta"
                    showTitle={true}
                    width={1200}
                    height={800}
                    contentRender={this.renderModal}
                ></Popup>
            </div>
        );
    }
}

export default ListaAlertas;
