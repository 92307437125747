import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, BrowserRouter } from "react-router-dom";

import "./assets/css/index.css";
//import './App.css';

//FontAwsome
import "@fortawesome/fontawesome-free/css/all.min.css";
//Boostrap 4
import "bootstrap/dist/css/bootstrap.min.css";
//DevExtreme
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import "./assets/css/devExtremeOrangeWhite.css";

//Now UI
//import "./assets/css/now-ui.css";
import "./assets/scss/now-ui-dashboard.scss";

//Propias
import "./assets/css/Kiwi.css";
import "./assets/css/StockOuts.css";

//Componentes
import LandingHome from "./views/LandingPage/LandingPage";

const hist = createBrowserHistory();

ReactDOM.render(
    // <React.StrictMode>
    <Router history={hist}>
        <BrowserRouter>
            <Switch>
                <Route path="/" render={(props) => <LandingHome {...props} />} />
            </Switch>
        </BrowserRouter>
    </Router>,
    //</React.StrictMode>
    document.getElementById("root")
);
