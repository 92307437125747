import React, { Component } from "react";
import TextBox from "devextreme-react/text-box";
import { Validator, RequiredRule } from "devextreme-react/validator";

class TextBoxComponente extends Component {
    constructor(props) {
        super(props);

        this.state = { value: "" };
    }
    onValueChanged = (e) => {
        this.setState({
            value: e.value,
        });
        if (this.props.onUpdate) this.props.onUpdate(e.value);
    };
    componentDidMount() {}
    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
        }
    }
    render() {
        return (
            <TextBox
                className={this.props.className}
                placeholder={this.props.placeHolder}
                value={this.props.value}
                onValueChanged={this.onValueChanged}
            >
                <Validator>
                    <RequiredRule message="Este campo es requerido!" />
                </Validator>
            </TextBox>
        );
    }
}

export default TextBoxComponente;
