import { config } from "./config";
import swal from "sweetalert";
import notify from "devextreme/ui/notify";

require("./prototypes");

var totalExecs = 0;

/**
 * Realiza una peticion a una URL especificada.
 *
 * @param {String} url Dirección donde se realizara la peticioón
 * @param {String} method Tipo de peticion a ejecutar (POST, GET, PUT, DELETE)
 * @param {JSON} [data={}] Objeto que se adjuntará al body de la petición
 * @returns
 */
async function callApi(url, method, data = {}, callBack) {
    method = method.toUpperCase();

    const headers = {
        "Content-Type": "application/json",
        "x-api-key": config.krakenKey,
        "x-access-token": getSessionItem("Token", ""),
    };

    console.log(`Url: ${url}`);

    fetch(url, {
        method,
        headers,
        dataType: "json",
        body: method === "GET" ? null : JSON.stringify(data),
    })
        .then(async function (response) {
            let res = await response.json();

            if (response.status === 200) {
                callBack(res);
            } else {
                throw Error(`${res.message}`);
            }
        })
        .catch((err) => {
            swal("Error", err.message, "error", {
                buttons: {
                    confirm: {
                        text: "Aceptar",
                        className: "animation-on-hover btn btn-success",
                    },
                },
            });
        });
}

/**
 * Función para realizar la petición a Kraken por medio de su servicio en Node js
 * @param {Number} claProducto número de el producto, dueño de la entidad
 * @param {Number} idEntidad número de la entidad que se desea consultar
 * @param {Object} paramsSP parametros que necesita la entidad
 * @param {Object} tipoEstructura número
 * @param {Function} callback función de callback para manejo del resultado
 * @param {Number} paramsCase número de caso para llamar la API de Kraken. 0 como default.
 *  0 = Caso consulta de sp
 *  1 = Caso consulta de tabla
 *  2 = Caso consulta de documentación
 */
async function callKrakenApi(claProducto, idEntidad, paramsSP, tipoEstructura, callback, paramsCase = 0) {
    const method = "POST";

    let params = null;
    let url = "";

    switch (paramsCase) {
        case 0:
            params = {
                parameters: JSON.stringify(paramsSP),
                tipoEstructura: tipoEstructura,
            };
            url = `${config.urlKrakenAPI}/${claProducto}/${idEntidad}`;
            break;
        case 1:
            params = {
                columnas: paramsSP.Columnas,
                condicion: paramsSP.Condicion,
                tipoEstructura: tipoEstructura,
            };
            url = `${config.urlKrakenAPI}/${claProducto}/${idEntidad}`;
            break;
        case 2:
            params = {
                parameters: JSON.stringify(paramsSP),
                tipoEstructura: tipoEstructura,
            };
            url = `${config.urlKrakenAPI}/${claProducto}/${idEntidad}?doc=1`;
            break;
    }

    await callApi(url, method, params, callback);
}

/**
 * Guarda en el localStorage cualquier valor que se mande en un objeto
 *
 * @param {object} params objeto donde cada propiedad se va a guardar
 */
function setSessionData(params) {
    for (const key in params) {
        if (params.hasOwnProperty(key)) {
            const element = params[key];

            if (typeof element === "object") {
                localStorage.setItem(key, JSON.stringify(element));
            } else {
                localStorage.setItem(key, element);
            }
        }
    }
}

/**
 * Regresa del localStorage cualquier valor que se pase como primer parametro,
 * en caso de no encontrarlo, regresa el valor por default del segundo parametro
 *
 * @param {string} key
 * @param {any} def
 */
function getSessionItem(key, def) {
    let value;

    try {
        value = JSON.parse(localStorage.getItem(key));
    } catch (error) {
        value = localStorage.getItem(key);
    }

    if (!value) {
        return def;
    }
    return value;
}

/**
 * Regresa del sessionStorage el NumUsuario
 *
 * @returns {Number} NumUsuario
 */
function getCliente() {
    return localStorage.getItem("NumUsuario");
}

/**
 * Remueve del localStorage el JWT token y el nombre del usuario
 *
 */
function logOut() {
    localStorage.clear();
}

/**
 * Hace el decode del JWT token
 *
 * @param {string} token
 * @returns {object} payload del token
 */
function decodeToken(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split("")
            .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
            .join("")
    );

    return JSON.parse(jsonPayload);
}

/**
 * Valida la si existe un token guardado en localStorage y en caso de existir
 * se valida su expiracion
 *
 * @returns {boolean} retorna si el token es valido o no
 */
function sessionAlive() {
    const jwtToken = localStorage.getItem("Token");
    let resp = false;

    if (jwtToken) {
        const { exp } = decodeToken(jwtToken);

        if (Date.now() < exp * 1000) {
            resp = true;
        }
    }

    if (!resp) {
        logOut();
    }

    return resp;
}

/**
 * Tipo de la notificacion de DevExtreme
 */
const notifyType = {
    success: "success",
    error: "error",
    info: "info",
    warning: "warning",
};

/**
 * Posicion la notificacion de DevExtreme
 * 'bottom' | 'center' | 'left' | 'left bottom' | 'left top' | 'right' | 'right bottom' | 'right top' | 'top'
 */
const notifyPosition = {
    centerBottom: "center bottom",
    centerTop: "center top",
    rightBottom: "right bottom",
    rightTop: "right top",
};

/**
 * Manda Un mensaje Generico con el Notify de DevExtreme
 *
 * @param {string} message mensaje de la alerta
 * @param {notifyPosition} notifyPosition posicion de la alerta
 * @param {notifyType} notifyType tipo de notificacion
 */
function showNotify(message, notifyPosition, notifyType) {
    notify(
        {
            message: message,
            position: {
                my: notifyPosition,
                at: notifyPosition,
            },
            width: "400px",
            closeOnSwipe: true,
        },
        notifyType,
        3000
    );
}

function showSweetAlert(title, message, notifyType) {
    swal(title, message, notifyType, {
        buttons: {
            confirm: {
                text: "Aceptar",
                className: "animation-on-hover btn btn-success",
            },
        },
    });
}

/************************Funciones que se realizaron en kiwi************************/
/**
 * showLoad()
 * Muestra la animacion de espera de carga de la pagina
 *
 */
function showLoad() {
    if (totalExecs === 0) {
        ShowLoading("fas fa-kiwi-bird", "#f96332");
    }
    ++totalExecs;
}
/**
 * hideLoad()
 *Oculta la animacion de espera de carga de la pagina
 */
function hideLoad() {
    --totalExecs;
    if (totalExecs === 0) {
        HideLoading();
    }
}

/**
 * Función para mostrar spinner en medio de la pantalla
 * @param {string} spinner clases de font awesome que se va a mostrar como spinner
 * @param {string} color color que se le quiera dar al spinner
 */
function ShowLoading(spinner, color) {
    if (!color) {
        color = "#000000";
    }

    if (!spinner) {
        spinner = "fa fa-spinner";
    }

    let divParent = document.createElement("div");
    let divInner = document.createElement("div");
    let iChild = document.createElement("i");

    divParent.id = "dialogLoading";
    divParent.classList.add("loader-parent");
    divInner.style.color = color;
    divInner.classList.add("loader-content");

    spinner.split(" ").forEach(function (item) {
        iChild.classList.add(item);
    });

    //iChild.classList.add(spinner);
    iChild.classList.add("fa-pulse");
    iChild.classList.add("fa-2x");
    iChild.classList.add("fa-fw");

    divInner.append(iChild);
    divParent.append(divInner);

    document.getElementsByTagName("body")[0].append(divParent);
}

/**
 * Función para ocultar spinner
 */
function HideLoading() {
    let divParent = document.getElementById("dialogLoading");
    document.getElementsByTagName("body")[0].removeChild(divParent);
}

/**
 * Formatea una fecha dada para poderla enviar a sqlServer
 * @param {Date} date Fecha que se desea formatear
 */
function formatDateForSql(date) {
    let date2 = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    let time = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
    let dateTime = date2 + " " + time;

    return dateTime;
}

/**
 * mapGroupBy(arr, prop)
 * Genera un objeto Map desde un Array
 *
 * @param {Array} arr objeto a convertir en Map.
 * @param {*} prop propiedad para generar el key del Map.
 * @returns Map resultado de agrupación de arr tomando en cuenta como key "prop"
 */
function mapGroupBy(arr, prop) {
    const map = new Map(Array.from(arr, (obj) => [obj[prop], []]));
    arr.forEach((obj) => map.get(obj[prop]).push(obj));
    return map;
}
/**********************************************************************************/

export {
    callApi,
    callKrakenApi,
    setSessionData,
    getSessionItem,
    getCliente,
    logOut,
    decodeToken,
    sessionAlive,
    notifyType,
    notifyPosition,
    showNotify,
    showSweetAlert,
    showLoad,
    hideLoad,
    formatDateForSql,
    mapGroupBy,
};
