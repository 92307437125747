import { callApi, showNotify, notifyType, notifyPosition, mapGroupBy } from "./utils/utils";
import { config } from "./utils/config";

import Usuarios from "./views/Usuarios/Usuarios";
import Generador from "./views/Generador/Generador";
import Paginas from "./views/Paginas/Paginas";
import Alertas from "./views/Alertas/Alertas";
import AlertasSubproducto from "./views/Alertas/AlertasSubproducto";
import MisAlertas from "./views/Alertas/MisAlertas";

const Componentes = [
    {
        name: "Usuarios",
        component: Usuarios,
    },
    {
        name: "Generador",
        component: Generador,
    },
    {
        name: "Paginas",
        component: Paginas,
    },
    { name: "Alertas", component: Alertas },
    {
        name: "AlertasSubproducto",
        component: AlertasSubproducto,
    },
    {
        name: "MisAlertas",
        component: MisAlertas,
    },
];

function obtenerComponente(name) {
    try {
        return Componentes.find((oX) => oX.name === name).component;
    } catch {
        return undefined;
    }
}

export function getMenu(callback) {
    let urlWebService = config.urlKiwiAPI + "config/GetMenu";
    let redirect;
    let response = [];
    let menu = [];

    try {
        const functionCallback = (res) => {
            let mapData = mapGroupBy(res.data, "subProducto");

            mapData.forEach(function (itemData, key, map) {
                let itemtitle = key;

                let itemColapse = {
                    collapse: true,
                    path: itemtitle,
                    name: itemtitle,
                    state: "open" + itemtitle,
                    icon: itemData[0].icono,
                    views: [],
                };

                itemData.forEach(function (mapItem) {
                    let menuItem = {
                        path: mapItem.ruta,
                        name: mapItem.texto,
                        mini: mapItem.indicador,
                        component: obtenerComponente(mapItem.componente),
                        layout: "/Layout",
                        subProducto: mapItem.subProducto,
                    };

                    if (mapItem.esBase !== 1) {
                        menuItem.link = "/" + mapItem.subProducto + mapItem.ruta;
                    } else {
                        menuItem.link = "/Layout" + mapItem.ruta;
                    }

                    if (!redirect) {
                        redirect = menuItem;
                    }
                    itemColapse.views.push(menuItem);
                });

                menu.push(itemColapse);
            });

            response.push(menu);

            response.push(redirect);

            callback(response);
        };

        callApi(urlWebService, "GET", null, functionCallback).catch((err) => {
            showNotify("Ha ocurrido un error: " + err.message, notifyPosition.centerTop, notifyType.error);
            console.log("Error: " + err.message);
        });
    } catch (err) {
        showNotify("Ha ocurrido un error: " + err.message, notifyPosition.centerTop, notifyType.error);
        console.log("Error: " + err.message);
    }
}
