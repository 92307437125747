import React, { Component } from "react";
import { Link } from "react-router-dom";
import { config } from "../../utils/config";
import { showNotify, notifyType, notifyPosition } from "../../utils/utils";
import { Row, Col } from "reactstrap";

import logo_kiwi from "../../assets/img/kiwi_black.png";
import logo_dlabs from "../../assets/img/logo_dlabs.png";

const isEmpty = (str) => {
    return !str || 0 === str.length;
}

//Componente manejador de cambio de Contrase;a
class CambiaPass extends Component {
    constructor(props) {
        super(props);
        this.state = {};

        //bindeo la funcion de Cambio de Contrase;a
        this.onSubmit = this.onSubmit.bind(this);
        this.onCambiaPass = this.onCambiaPass.bind(this);
    }
    onCambiaPass(response) {
        if (response) {
            if (isEmpty(response.Mensaje)) {
                showNotify(
                    "Se ha cambiado su Contraseña correctamente",
                    notifyPosition.centerBottom,
                    notifyType.success
                );
            } else {
                showNotify(response.Mensaje, notifyPosition.centerBottom, notifyType.error);
            }
        }
    }
    async onSubmit(event) {
        //Cancelo el Submit Refresh
        event.preventDefault();

        //Obtengo los datos del form de Inicio de Sesion
        const dataForm = new FormData(event.target);

        //Valido los datos
        if (isEmpty(dataForm.get("user")))
            showNotify("Favor de capturar el Usuario", notifyPosition.centerBottom, notifyType.error);
        else if (isEmpty(dataForm.get("pass")))
            showNotify("Favor de capturar la Contraseña", notifyPosition.centerBottom, notifyType.error);
        else if (isEmpty(dataForm.get("pass1")))
            showNotify("Favor de capturar la nueva Contraseña", notifyPosition.centerBottom, notifyType.error);
        else if (isEmpty(dataForm.get("pass2")))
            showNotify("Favor de confirmar la nueva Contraseña", notifyPosition.centerBottom, notifyType.error);
        else if (dataForm.get("pass1") !== dataForm.get("pass2"))
            showNotify("Las Contraseñas no coinciden", notifyPosition.centerBottom, notifyType.error);
        else {
            //Servicio para Login
            const urlWebService = config.urlWebService + "/ChangePassword";

            //Hacer la peticion al servicio de Sweet Aqui
        }
    }
    componentDidMount() {}
    render() {
        return (
            <div className="limiter">
                <div className="container-login100">
                    <div className="wrap-login100 p-t-85 p-b-20">
                        <span className="login100-form-avatar">
                            <img src={logo_kiwi} alt="Kiwi DTO" />
                        </span>
                        <form method="POST" className="needs-validation" noValidate onSubmit={this.onSubmit}>
                            <div className="form-group">
                                <div
                                    className="wrap-input100 validate-input m-t-130 m-b-35"
                                    data-validate="Capture Usuario"
                                >
                                    <span className="input100-span">Usuario</span>
                                    <input id="userIdCambia" className="input100" type="text" name="user" required />
                                </div>
                                <div className="wrap-input100 validate-input m-b-50" data-validate="Capture Contraseña">
                                    <span className="input100-span">Contraseña</span>
                                    <input
                                        id="passwordIdCambia"
                                        className="input100"
                                        type="password"
                                        name="pass"
                                        required
                                    />
                                </div>

                                <div className="wrap-input100 validate-input m-b-50" data-validate="Capture Contraseña">
                                    <span className="input100-span">Contraseña Nueva</span>
                                    <input
                                        id="newpasswordIdCambia"
                                        className="input100"
                                        type="password"
                                        name="pass1"
                                        required
                                    />
                                </div>

                                <div
                                    className="wrap-input100 validate-input m-b-50"
                                    data-validate="Capture Contraseña"
                                    required
                                >
                                    <span className="input100-span">Confirmar Contraseña</span>
                                    <input
                                        id="confirmnewpasswordIdCambia"
                                        className="input100"
                                        type="password"
                                        name="pass2"
                                    />
                                </div>

                                <div className="container-login100-form-btn">
                                    <button type="submit" id="btnEnter" className="login100-form-btn">
                                        Guardar
                                    </button>
                                </div>
                            </div>
                        </form>

                        <div className="login-more p-t-70" style={{ textAlign: "center" }}>
                            <Row className="m-b-8">
                                <Col>
                                    <Link to="/login" className="txt2">
                                        Cancelar
                                    </Link>
                                </Col>
                            </Row>
                        </div>
                        <div className="container-login100-form-btn">
                            <span style={{ textAlign: "center" }}>
                                Powered by <img src={logo_dlabs} alt="DLabs" className="powered-logo" />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CambiaPass;
