import React, { Component } from "react";
import { Popup } from "devextreme-react/popup";
import { Button, Card, CardHeader, CardBody, CardTitle, Row, Col } from "reactstrap";
import DataGrid, { Column, Paging, Editing, Lookup, FilterRow, RequiredRule } from "devextreme-react/data-grid";
import TagBox from "devextreme-react/tag-box";
import SelectBox from "devextreme-react/select-box";
import { showNotify, notifyType, notifyPosition, callKrakenApi } from "../../utils/utils";
import { callApi } from "@tlugo-deacero/dlabs-utils";
import { config } from "../../utils/config";
import AlertaAlta from "../Alertas/AlertaAlta";
import "./AlertaHTMLEditor.css";

class Alertas extends Component {
    constructor(props) {
        super(props);

        this.state = {
            popupVisible: false,
            subProductos: [],
            subProductos2: [],
            subProductoSelected: -1,
            clickEstatus: -1,
            descripcion: "",
            tipoalertas: [
                {
                    claTipoAlerta: 1,
                    descripcion: "Correo Eelectronico",
                },
                {
                    claTipoAlerta: 2,
                    descripcion: "Push Notification",
                },
                {
                    claTipoAlerta: 3,
                    descripcion: "Whats App",
                },
            ],
            alertas: [],
            destinatarios: [],
            acciones: [],
            destinatariosSeleccionados: [],
            accionesSeleccionadas: [],

            ///Para el paso de parametros de edicion de una alerta
            claTipoAlerta: -1,
            claSubProducto: -1,
            esCrítico: false,
            plantillaCuerpo: "",
            plantillaTitulo: "",
            plantillaDescripcion: "",
            titulo: "",
            idAlerta: 0,
        };

        this.onRowUpdated = this.onRowUpdated.bind(this);
        this.onRowRemoved = this.onRowRemoved.bind(this);
        this.onSelectSubProducto = this.onSelectSubProducto.bind(this);
        this.getSubProductos = this.getSubProductos.bind(this);
        this.getAlertas = this.getAlertas.bind(this);

        this.refAlertaPopUp = React.createRef();
    }

    GridAlertasEditRenderer = (e) => {
        return (
            <div className="edit-cell">
                <Button
                    id={`btnEditRow`}
                    onClick={(b) => {
                        this.onClickEditar(b, e.data.idAlerta);
                    }}
                    size="md"
                    className="btn btn-primary btn-round btn-icon btn-custom"
                    style={{ backgroundColor: "#fa7a50;" }}
                >
                    <i className="fa fa-edit" />
                </Button>
            </div>
        );
    };

    GridAlertasElimRenderer = () => {
        return (
            <div className="delete-cell">
                <Button
                    id={`btnElimRow`}
                    onClick={this.onClickEliminar}
                    size="md"
                    className="btn btn-primary btn-round btn-icon btn-custom"
                    style={{ backgroundColor: "#fa7a50;" }}
                >
                    <i className="fa fa-trash" />
                </Button>
            </div>
        );
    };

    /**
     * Funcion que se abre el popup para dar de alta un usuario
     * @param {Obj} e objeto del popup
     */
    onClickAgregar = (e) => {
        this.setState({
            accionesSeleccionadas: [],
            destinatariosSeleccionados: [],
            claTipoAlerta: "",
            claSubProducto: "",
            descripcion: "",
            esCrítico: false,
            plantillaCuerpo: "",
            plantillaTitulo: "",
            plantillaDescripcion: "",
            titulo: "",
            idAlerta: 0,

            clickEstatus: 0,
            popupVisible: true,
        });

        e.stopPropagation();
    };

    onClickEditar = (e, idAlerta) => {
        let alerta = this.state.alertas.filter((elemento) => elemento.idAlerta === idAlerta);

        this.setState({
            accionesSeleccionadas: this.state.acciones.filter((elemento) => elemento.idAlerta === idAlerta),
            destinatariosSeleccionados: this.state.destinatarios.filter((elemento) => elemento.idAlerta === idAlerta),
            claTipoAlerta: alerta[0].claTipoAlerta,
            claSubProducto: alerta[0].claSubProducto,
            esCrítico: alerta[0].esCritico === "Si" ? true : false,
            plantillaCuerpo: alerta[0].plantillaCuerpo,
            plantillaTitulo: alerta[0].plantillaTitulo,
            plantillaDescripcion: alerta[0].plantillaDescripcion,
            titulo: alerta[0].plantillaTitulo,
            idAlerta: idAlerta,
            clickEstatus: 1,
            popupVisible: true,
        });
        //this.refAlertaPopUp.current

        e.stopPropagation();
    };

    CleanEdit() {
        this.refAlertaPopUp.current.resetHandle();
    }

    onClickEliminar = (e) => {
        this.setState({
            clickEstatus: 2,
            popupVisible: true,
        });

        e.stopPropagation();
    };

    /**
     * Funcion que se oculta el popup de alta de usuario
     */
    hidePopUp = () => {
        this.setState({
            popupVisible: false,
        });
    };

    showPopUp = () => {
        this.setState({
            popupVisible: true,
        });

        this.CleanEdit();
    };

    /**
     * Funcion que actualiza el estatus del popup de alta de usuario
     * @param {Boolean} val estatus del popop
     */
    handler = (val) => {
        this.setState({
            popupVisible: val,
        });
    };

    /**
     * Funcion para la seleccion del subproducto para guardar el dato en el state
     * @param {*} data
     */
    onSelectSubProducto(data) {
        this.setState({
            subProductoSelected: data.value,
        }, () => this.getAlertas());
    }

    /**
     * Funcion que se ejecuta al actualizar un usuario
     * @param {Obj} e objeto del row que se esta actualizando
     */
    onRowUpdated(e) {}

    /**
     * Funcion que se ejecuta al dar de baja un usuario
     * @param {Obj} e objeto del row que se esta dando de baja
     */
    onRowRemoved(e) {}

    /**
     * Funcion que obtiene los subproductos
     */
    getSubProductos() {
        //Obtener SubProductos
        const urlSubProductos = config.urlKiwiAPI + "usuarios/usuariosObtenerSubProductos";
        callApi(config, urlSubProductos, "GET", null).then((res) => {
            //Agrega el subproducto de todos
            const todos = {
                claEmpleado: null,
                claPerfil: 0,
                claSubProducto: -1,
                claUbicacion: 0,
                id: 0,
                idUsuario: 0,
                nomPerfil: null,
                nomSubProducto: "Todos",
                nombre: null,
                opcion: 0,
            };

            // 

            //Elimina los subproductos de Configuracion y Alertas
            res.data = res.data.filter(function (e) {
                return e.claSubProducto !== 1;
            });

            res.data = res.data.filter(function (e) {
                return e.claSubProducto !== 4;
            });

            res.data.push(todos);
            this.setState({ subProductos: res.data });
        });
    }

    /**
     * Metodo que obtiene las alertas generadas
     */
    getAlertas() {
        const urlWebService = config.urlKiwiAPI + "Alert/GetGeneratedAlerts/" + this.state.subProductoSelected;

        callApi(config, urlWebService, "GET", null).then((res) => {
            this.setState({
                alertas: res.data.alertas,
                destinatarios: res.data.destinatarios,
                acciones: res.data.acciones,
            });
        });
    }

    componentDidMount() {
        this.getAlertas();

        this.getSubProductos();

        //this.getPerfiles();

        //this.getUbicaciones();
    }

    elementDestinatario() {
        return (
            <TagBox
                dataSource={this.props.data.column.lookup.dataSource}
                defaultValue={this.props.data.value}
                valueExpr="ID"
                displayExpr="FullName"
                showSelectionControls={true}
                maxDisplayedTags={3}
                showMultiTagOnly={false}
                applyValueMode="useButtons"
                searchEnabled={true}
                onValueChanged={this.onValueChanged}
                onSelectionChanged={this.onSelectionChanged}
            />
        );
    }

    cellTemplate(container, options) {
        var noBreakSpace = "\u00A0",
            text = (options.value || [])
                .map((element) => {
                    return options.column.lookup.calculateCellValue(element);
                })
                .join(", ");
        container.textContent = text || noBreakSpace;
        container.title = text;
    }

    calculateFilterExpression(filterValue, selectedFilterOperation, target) {
        if (target === "search" && typeof filterValue === "string") {
            return [this.dataField, "contains", filterValue];
        }
        return function (data) {
            return (data.AssignedEmployee || []).indexOf(filterValue) !== -1;
        };
    }

    render() {
        const GridAlertas = (
            <DataGrid
                dataSource={this.state.alertas}
                showRowLines={true}
                //onRowUpdated={this.onRowUpdated}
                //onRowRemoved={this.onRowRemoved}
                noDataText={"Sin Registros"}
            >
                <FilterRow visible={false} showAllText={"Todos"} />
                <Paging defaultPageSize={8} />
                <Editing mode="row" allowUpdating={false} allowDeleting={false} allowAdding={false} width={200} />

                <Column dataField="idAlerta" caption="ID" allowEditing={false} alignment={"center"} width={100} />
                <Column
                    dataField="descripcion"
                    caption="Tipo de alerta"
                    allowEditing={false}
                    alignment={"center"}
                    width={200}
                />
                <Column dataField="nomSubProducto" caption="Subproducto" allowEditing={false} width={200} />
                <Column dataField="esCritico" caption="Criticidad" allowEditing={false} width={100} />
                <Column
                    dataField="destinatarios"
                    caption="Destinatarios"
                    allowEditing={false}
                    //editCellComponent={this.elementDestinatario}
                    //cellTemplate={this.cellTemplate}
                    //calculateFilterExpression={this.calculateFilterExpression}
                >
                    {/* <Lookup dataSource={this.state.destinatarios} valueExpr="ID" displayExpr="FullName" />
                    <RequiredRule /> */}
                </Column>

                <Column dataField="plantillaTitulo" caption="Tema" allowEditing={false} />
                <Column dataField="plantillaDescripcion" caption="Descripción" allowEditing={false} />
                <Column caption="Editar" cellRender={this.GridAlertasEditRenderer} width={100} />
                <Column caption="Eliminar" cellRender={this.GridAlertasElimRenderer} width={100} />
            </DataGrid>
        );

        const GridAlertasServicio = () => {
            return <div>{GridAlertas}</div>;
        };

        return (
            <>
                <div className="content">
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <CardTitle tag="h4">Configuración de Alertas</CardTitle>
                                </Col>
                            </Row>
                            <Row className="align-items-start">
                                <Col md="3" lg="3">
                                    <span
                                        className="dx-field-label"
                                        style={{ color: "black", fontSize: "1em", fontWeight: "bold" }}
                                    >
                                        SubProducto:
                                    </span>
                                    <SelectBox
                                        dataSource={this.state.subProductos}
                                        searchEnabled={true}
                                        noDataText="No hay SubProductos"
                                        placeholder="SubProducto"
                                        valueExpr="claSubProducto"
                                        displayExpr="nomSubProducto"
                                        value={this.state.subProductoSelected}
                                        onValueChanged={this.onSelectSubProducto}
                                        className=""
                                    />
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md={{ size: 1, offset: 11 }} style={{ textAlign: "center" }}>
                                    <Button
                                        id={`btnNuevo`}
                                        onClick={this.onClickAgregar}
                                        size="md"
                                        className="btn btn-primary btn-round btn-icon btn-custom"
                                        style={{ backgroundColor: "#fa7a50;" }}
                                    >
                                        <i className="fa fa-plus" />
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <GridAlertasServicio />
                            </Row>
                        </CardBody>
                    </Card>
                </div>

                <Popup
                    key={"popUpAlertas"}
                    id={"popUpAlertas"}
                    visible={this.state.popupVisible}
                    onHiding={this.hidePopUp}
                    onShown={this.showPopUp}
                    dragEnabled={false}
                    closeOnOutsideClick={true}
                    showTitle={true}
                    title="Alta de Alertas"
                    width={1000}
                    height={570}
                >
                    <AlertaAlta
                        ref={this.refAlertaPopUp}
                        handler={this.handler}
                        tipoalertas={this.state.tipoalertas}
                        estatus={this.state.clickEstatus}
                        subproductos={this.state.subProductos}
                        acciones={this.state.accionesSeleccionadas}
                        destinatarios={this.state.destinatariosSeleccionados}
                        claTipoAlerta={this.state.claTipoAlerta}
                        claSubProducto={this.state.claSubProducto}
                        //descripcion={this.state.descripcion}
                        esCritico={this.state.esCrítico}
                        plantillaCuerpo={this.state.plantillaCuerpo}
                        plantillaTitulo={this.state.plantillaTitulo}
                        plantillaDescripcion={this.state.plantillaDescripcion}
                        titulo={this.state.plantillaTitulo}
                        idAlerta={this.state.idAlerta}
                    />
                </Popup>
            </>
        );
    }
}

export default Alertas;
