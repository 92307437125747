export const filtroMultiSelect = `import React, { Component } from "react";
import DropDownBox from "devextreme-react/drop-down-box";
import TreeView from "devextreme-react/tree-view";
import { Button } from "devextreme-react/button";
import { callKrakenApi } from "../../utils/utils";
import { config } from "../../utils/config";

class #Nombre extends Component {
    constructor(props) {
        super(props);

        this.treeView = null;
        this.state = {
            treeBoxValue: [],
            openDropdown: false,
            dataSource: null,
        };
        this.treeView_itemSelectionChanged = this.treeView_itemSelectionChanged.bind(this);
        this.syncTreeViewSelection = this.syncTreeViewSelection.bind(this);
        this.treeViewRender = this.treeViewRender.bind(this);

        this.dropRef = React.createRef();
    }
    getData() {
        let krakenParams = {
            ClaProducto: 8,
            IdEntidad: 128,
            TipoEstructura: 6,
            ParamsSP: null,
            Callback: null,
        };

        //Parametros que recibe el servicio
        krakenParams.ParamsSP = {
          
        };

        krakenParams.Callback = (response) => {
            this.setState({ dataSource: response.Result0 });
        };

        //Hacemos la peticion para el servicio el indicador
        callKrakenApi(
            krakenParams.ClaProducto,
            krakenParams.IdEntidad,
            krakenParams.ParamsSP,
            krakenParams.TipoEstructura,
            krakenParams.Callback
        );
    }
    treeViewRender() {
        return (
            <div>
                <TreeView
                    dataSource={this.state.dataSource}
                    ref={(ref) => (this.treeView = ref)}
                    height={250}
                    dataStructure="tree"
                    keyExpr={"id"}
                    selectionMode="multiple"
                    showCheckBoxesMode="normal"
                    expandedExpr="expanded"
                    disabledExpr="disabled"
                    scrollDirection="vertical"
                    selectNodesRecursive={true}
                    noDataText={"No hay datos"}
                    displayExpr={"text"}
                    selectByClick={true}
                    onContentReady={this.syncTreeViewSelection}
                    onItemSelectionChanged={this.treeView_itemSelectionChanged}
                />
                <div style={{ width: 110, float: "right", margin: "15px" }}>
                    <Button
                        text="Aplicar"
                        type="normal"
                        width={120}
                        onClick={() => {
                            if (this.props.onSelectCallBack)
                                this.props.onSelectCallBack(this.state.treeBoxValue.join(","));

                            if (this.dropRef.current.instance) this.dropRef.current.instance.option("opened", false);
                        }}
                    ></Button>
                </div>
            </div>
        );
    }
    syncTreeViewSelection(e) {
        let treeView = (e.component.selectItem && e.component) || (this.treeView && this.treeView.instance);

        if (treeView) {
            if (e.value === null) {
                treeView.unselectAll();
                if (this.props.onSelectCallBack) this.props.onSelectCallBack(null);
                if (this.dropRef.current.instance) this.dropRef.current.instance.option("opened", false);
            } else {
                let values = e.value || this.state.treeBoxValue;
                values &&
                    values.forEach(function (value) {
                        treeView.selectItem(value);
                    });
            }
        }

        if (e.value !== undefined) {
            this.setState({
                treeBoxValue: e.value,
            });
        }
    }
    treeView_itemSelectionChanged(e) {
        this.setState({
            treeBoxValue: e.component.getSelectedNodeKeys().filter(v => v.includes('-')),
        });
    }
    componentDidMount() {
        this.getData();
    }
    render() {
        return (
            <div style={{ marginLeft: "5px", marginRight: "5px" }}>
                <DropDownBox
                    width={330}
                    value={this.state.treeBoxValue}
                    valueExpr={"id"}
                    displayExpr={"text"}
                    placeholder={"Seleccione Algo"}
                    showClearButton={true}
                    dataSource={this.state.dataSource}
                    onValueChanged={this.syncTreeViewSelection}
                    contentRender={this.treeViewRender}
                    ref={this.dropRef}
                />
            </div>
        );
    }
}

export default #Nombre ;`;
