import React, { Component } from "react";
import { Link } from "react-router-dom";
import { config } from "../../utils/config";
import { showNotify, notifyType, notifyPosition } from "../../utils/utils";
import { Row, Col } from "reactstrap";

import logo_kiwi from "../../assets/img/kiwi_black.png";
import logo_dlabs from "../../assets/img/logo_dlabs.png";

//Componente manejador de Recuperacion de Contrase;a
class RecuperaPass extends Component {
    constructor(props) {
        super(props);
        this.state = {};

        //bindeo la funcion de Submit
        this.onSubmit = this.onSubmit.bind(this);
        this.onRecoverPass = this.onRecoverPass.bind(this);
    }
    onRecoverPass(response) {
        if (response) {
            showNotify(response.Mensaje, notifyPosition.centerBottom, notifyType.success);
        }
    }
    async onSubmit(event) {
        //Cancelo el Submit Refresh
        event.preventDefault();

        //Obtengo los datos del form de Inicio de Sesion
        const dataForm = new FormData(event.target);

        //Valido los datos
        const userName = dataForm.get("user");

        if (!userName || 0 === userName.length)
            showNotify("Favor de capturar el Usuario", notifyPosition.centerBottom, notifyType.error);
        else {
            //Servicio para Login
            const urlWebService = config.urlWebService + "/RecoverPassword";

            //Hacer la peticion al servicio de Sweet Aqui
        }
    }
    componentDidMount() {}
    render() {
        return (
            <div className="limiter">
                <div className="container-login100">
                    <div className="wrap-login100 p-t-85 p-b-20">
                        <span className="login100-form-avatar">
                            <img src={logo_kiwi} alt="Kiwi DTO" />
                        </span>

                        <form method="POST" className="needs-validation" noValidate onSubmit={this.onSubmit}>
                            <div className="form-group">
                                <div
                                    className="wrap-input100 validate-input m-t-130 m-b-35"
                                    data-validate="Capture Usuario"
                                >
                                    <span className="input100-span">Usuario</span>
                                    <input id="userIdRecupera" className="input100" type="text" name="user" required />
                                </div>

                                <div className="container-login100-form-btn">
                                    <button id="btnEnter" className="login100-form-btn" type="submit">
                                        Enviar Correo
                                    </button>
                                </div>
                            </div>
                        </form>

                        <div className="login-more p-t-70" style={{ textAlign: "center" }}>
                            <Row className="m-b-8">
                                <Col>
                                    <Link to="/login" className="txt2">
                                        Cancelar
                                    </Link>
                                </Col>
                            </Row>
                        </div>
                        <div className="container-login100-form-btn">
                            <span style={{ textAlign: "center" }}>
                                Powered by <img src={logo_dlabs} alt="Kiwi DTO" className="powered-logo" />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default RecuperaPass;
