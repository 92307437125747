import React, { Component } from "react";
import SideBar from "../components/core/Sidebar";
import NavBar from "../components/core/Navbar";
import { Route, Switch, Redirect, Router } from "react-router-dom";

import { logOut, sessionAlive } from "../utils/utils";
import { menu, getMenu } from "../menu";
import PerfectScrollbar from "perfect-scrollbar";

var ps;
//Componente manejador de PaginaInicial
class Layout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sidebarMini: true,
            menuLoaded: false,
        };

        this.mainPanel = React.createRef();
    }
    getRoutes = (routes) => {
        return routes.map((prop, key) => {
            if (prop.collapse) {
                return this.getRoutes(prop.views);
            }
            //if (prop.layout === "/Layout") {
            return <Route path={prop.link} component={prop.component} key={key} />;
            //} else {
            //return null;
            //}
        });
    };
    cerrarSesion() {
        logOut();
        window.location.href = "/login";
    }
    minimizeSidebar = () => {
        if (document.body.classList.contains("sidebar-mini")) {
            this.setState({ sidebarMini: false });
        } else {
            this.setState({ sidebarMini: true });
        }
        document.body.classList.toggle("sidebar-mini");
    };
    componentDidMount() {
        getMenu((response) => {
            if (navigator.platform.indexOf("Win") > -1) {
                if (this.mainPanel.current) {
                    ps = new PerfectScrollbar(this.mainPanel.current);
                }
                document.body.classList.toggle("perfect-scrollbar-on");
            }
            //Setea el Sidebar-Mini
            document.body.classList.toggle("sidebar-mini");

            this.setState({
                menuLoaded: true,
                route: response[1] ? response[1] : response[0][0].views[0].layout + response[0][0].views[0].path,
                menu: response[0],
            });
        });
    }
    componentWillUnmount() {
        if (navigator.platform.indexOf("Win") > -1) {
            if (typeof ps !== "undefined") {
                ps.destroy();
            }
            document.body.classList.toggle("perfect-scrollbar-on");
        }
    }
    componentDidUpdate(e) {
        if (e.history.action === "PUSH") {
            document.documentElement.scrollTop = 0;
            document.scrollingElement.scrollTop = 0;
            this.mainPanel.current.scrollTop = 0;
        }
    }
    render() {
        //Manejamos la sesion en pagina principal
        if (sessionAlive()) {
            if (this.state.menuLoaded) {
                if (this.state.route.component) {
                    return (
                        <div className="wrapper">
                            <SideBar
                                {...this.props}
                                menu={this.state.menu}
                                backgroundColor={this.state.backgroundColor}
                                minimizeSidebar={this.minimizeSidebar}
                            />
                            <div className="main-panel" ref={this.mainPanel}>
                                <NavBar {...this.props} />
                                <div
                                    className={
                                        this.props.location.pathname === "/Layout/Kayak"
                                            ? "panel-header panel-header-m"
                                            : "panel-header panel-header-sm"
                                    }
                                >
                                    <div className="NavFiltros"></div>
                                </div>
                                <Switch>
                                    {this.getRoutes(this.state.menu)}
                                    <Redirect from="/Layout" to={this.state.route.link} />
                                </Switch>
                            </div>
                        </div>
                    );
                } else {
                    window.location.href = this.state.route.link;
                }
            } else {
                return (
                    <div id="dialogLoading" className="loader-parent">
                        <div className="loader-content" style={{ color: "#f96332" }}>
                            <i className="fas fa-kiwi-bird fa-pulse fa-2x fa-fw"></i>
                        </div>
                    </div>
                );
            }
        } else {
            this.cerrarSesion();
        }
    }
}

export default Layout;
