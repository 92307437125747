import React, { Component } from "react";
import { Button, Card, CardHeader, CardBody, Row, Col } from "reactstrap";
import SelectBox from "devextreme-react/select-box";
import { config } from "../../utils/config";
import { callApi } from "@tlugo-deacero/dlabs-utils";
import { formatDateForSql } from "../../utils/utils";
import ListaAlertas from "./ListaAlertas";
import DateBox from "devextreme-react/date-box";

class AlertasSubproducto extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentCount: 0,
            updateInfo: true,
            subProductos: [],
            subProductoSelected: -1,
            usuarios: [],
            usuarioSelected: -1,
            paginaActual: 1,
            prioridades: [
                {
                    claPrioridad: -1,
                    descripcion: "Todas",
                },
                {
                    claPrioridad: 1,
                    descripcion: "Crítica",
                },
                {
                    claPrioridad: 2,
                    descripcion: "Baja",
                },
            ],
            prioridadSelected: -1,
            fechaDesdeRecibido: new Date(),
            fechaHastaRecibido: new Date(),
            selectedTab: -1,
            alertas: [
                // {
                //     idBitacora: 1,
                //     titulo: "Prueba de alertas",
                //     descripcion: "esta es una prueba de alerta para ver como se mostraria",
                //     claEstatus: 1, //1 Esta pendiente, 2 Esta resuelto
                //     tipo: 1, //1 Es de lectura, 2 De accion
                //     esCritico: 1, //0 No lo es, 1 Lo es
                //     fechaRecibido: "2020/04/22",
                //     fechaLeido: null,
                //     fechaSolucion: null,
                //     responsableSolucion: null,
                //     solucion: null,
                // },
                // {
                //     idBitacora: 2,
                //     titulo: "Prueba de alertas 2",
                //     descripcion: "esta es una prueba 2 de alerta para ver como se mostraria",
                //     claEstatus: 1, //1 Esta pendiente, 2 Esta resuelto
                //     tipo: 2, //1 Es de lectura, 2 De accion
                //     esCritico: 1, //0 No lo es, 1 Lo es
                //     fechaRecibido: "2020/04/22",
                //     fechaLeido: "2020/04/22",
                //     fechaSolucion: null,
                //     responsableSolucion: null,
                //     solucion: null,
                // },
                // {
                //     idBitacora: 3,
                //     titulo: "Prueba de alertas 3",
                //     descripcion: "esta es una prueba 3 de alerta para ver como se mostraria",
                //     claEstatus: 2, //1 Esta pendiente, 2 Esta resuelto
                //     tipo: 1, //1 Es de lectura, 2 De accion
                //     esCritico: 0, //0 No lo es, 1 Lo es
                //     fechaRecibido: "2020/04/22",
                //     fechaLeido: "2020/04/22",
                //     fechaSolucion: null,
                //     responsableSolucion: "Anita lava la tina",
                //     solucion: null,
                // },
                // {
                //     idBitacora: 4,
                //     titulo: "Prueba de alertas 4",
                //     descripcion: "esta es una prueba 4 de alerta para ver como se mostraria",
                //     claEstatus: 2, //1 Esta pendiente, 2 Esta resuelto
                //     tipo: 2, //1 Es de lectura, 2 De accion
                //     esCritico: 1, //0 No lo es, 1 Lo es
                //     fechaRecibido: "2020/04/22 12:00:00",
                //     fechaLeido: "2020/04/22",
                //     fechaSolucion: "2020/04/22 17:30:00",
                //     responsableSolucion: "Pepe Da Bull",
                //     solucion: "Se resolvio picandole ahi",
                // },
                // {
                //     idBitacora: 5,
                //     titulo: "Prueba de alertas 5",
                //     descripcion: "esta es una prueba 5 de alerta para ver como se mostraria",
                //     claEstatus: 1, //1 Esta pendiente, 2 Esta resuelto
                //     tipo: 2, //1 Es de lectura, 2 De accion
                //     esCritico: 0, //0 No lo es, 1 Lo es
                //     fechaRecibido: "2020/04/22 12:00:00",
                //     fechaLeido: "2020/04/22",
                //     fechaSolucion: null,
                //     responsableSolucion: null,
                //     solucion: null,
                // },
            ],
            actualizaLista: false,
        };

        this.getAlertas = this.getAlertas.bind(this);
        this.getUsuarios = this.getUsuarios.bind(this);
        this.onSelectSubProducto = this.onSelectSubProducto.bind(this);
        this.getSubProductos = this.getSubProductos.bind(this);
        this.onSelectPrioridad = this.onSelectPrioridad.bind(this);
        this.onChangedFechaDesdeRecibido = this.onChangedFechaDesdeRecibido.bind(this);
        this.onChangedFechaHastaRecibido = this.onChangedFechaHastaRecibido.bind(this);
        this.onSelectedUsuario = this.onSelectedUsuario.bind(this);
        this.onClickFiltrar = this.onClickFiltrar.bind(this);
        this.onClickLimpiar = this.onClickLimpiar.bind(this);
        this.onClickAnterior = this.onClickAnterior.bind(this);
        this.onClickSiguiente = this.onClickSiguiente.bind(this);
        this.onClickNav = this.onClickNav.bind(this);
    }
    /**
     * Metodo para obtener las SubProductos
     */
    getSubProductos() {
        //Obtener SubProductos
        const urlSubProductos = config.urlKiwiAPI + "usuarios/usuariosObtenerSubProductos";
        callApi(config, urlSubProductos, "GET", null).then((res) => {
            //Agrega el subproducto de todos
            const todos = {
                claEmpleado: null,
                claPerfil: 0,
                claSubProducto: -1,
                claUbicacion: 0,
                id: 0,
                idUsuario: 0,
                nomPerfil: null,
                nomSubProducto: "Todos",
                nombre: null,
                opcion: 0,
            };

            //Elimina los subproductos de Configuracion y Alertas
            res.data = res.data.filter(function (e) {
                return e.claSubProducto !== 1;
            });

            res.data = res.data.filter(function (e) {
                return e.claSubProducto !== 4;
            });

            res.data.push(todos);
            this.setState({ subProductos: res.data });
        });
    }
    onClickNav(data) {
        let value = 0;
        let btnNavAlertaActive = document.getElementsByClassName("NavAlertaActive")[0];
        btnNavAlertaActive.classList.toggle("NavAlertaActive");

        let currentTarget = data.currentTarget;

        currentTarget.classList.toggle("NavAlertaActive");

        switch (currentTarget.id) {
            case "btnTodas":
                value = -1;
                break;
            case "btnPendientes":
                value = 1;
                break;
            case "btnResueltas":
                value = 2;
                break;
            default:
                value = -1;
                break;
        }

        this.setState({
            selectedTab: value,
        });

        this.getAlertas(value);
    }
    onClickAnterior() {
        let paginaActual = this.state.paginaActual;

        this.setState({
            paginaActual: paginaActual === 1 ? paginaActual : paginaActual--,
        });
    }
    onClickSiguiente() {
        let paginaActual = this.state.paginaActual;

        this.setState({
            paginaActual: paginaActual++,
        });
    }
    onClickFiltrar() {
        this.getUsuarios(this.state.selectedTab);
        this.getAlertas(this.state.selectedTab);
    }
    onClickLimpiar() {
        this.setState({
            selectedTab: 0,
            prioridadSelected: -1,
            subProductoSelected: -1,
            usuarios: [],
            usuarioSelected: -1,
            paginaActual: 1,
            fechaDesdeRecibido: new Date(),
            fechaHastaRecibido: new Date(),
        });
    }
    onChangedFechaDesdeRecibido(data) {
        this.setState({
            fechaDesdeRecibido: data.value,
        });
    }
    onChangedFechaHastaRecibido(data) {
        this.setState({
            fechaHastaRecibido: data.value,
        });
    }
    onSelectedUsuario(data) {
        this.setState({
            usuarioSelected: data.value,
        });
    }
    onSelectPrioridad(data) {
        this.setState({
            prioridadSelected: data.value,
        });
    }
    onSelectSubProducto(data) {
        this.setState({
            subProductoSelected: data.value,
        });
    }

    getUsuarios(selectedTab) {
        const urlWebService = config.urlKiwiAPI + "Alert/GetUsuarios";

        let data = {
            SubProducto: this.state.subProductoSelected,
            Estatus: selectedTab,
            Prioridad: this.state.prioridadSelected,
            FechaRecibidoIni: formatDateForSql(this.state.fechaDesdeRecibido).split(" ")[0],
            FechaRecibidoFin: formatDateForSql(this.state.fechaHastaRecibido).split(" ")[0],
            MisAlertas: 0,
        };

        callApi(config, urlWebService, "POST", data).then((res) => {
            this.setState({
                usuarios: res.data,
            });
        });

        this.setState({
            updateInfo: true,
            actualizaLista: false,
        });
    }

    getAlertas(selectedTab) {
        const urlWebService = config.urlKiwiAPI + "Alert/GetAlerts";

        let data = {
            SubProducto: this.state.subProductoSelected,
            Estatus: selectedTab,
            Prioridad: this.state.prioridadSelected,
            FechaRecibidoIni: formatDateForSql(this.state.fechaDesdeRecibido).split(" ")[0],
            FechaRecibidoFin: formatDateForSql(this.state.fechaHastaRecibido).split(" ")[0],
            MisAlertas: 0,
            ClaUsuarioFiltro: this.state.usuarioSelected,
        };

        callApi(config, urlWebService, "POST", data).then((res) => {
            this.setState({
                alertas: res.data,
                currentCount: 0,
                updateInfo: false,
                actualizaLista: true,
            });
        });

        this.setState({
            updateInfo: true,
            actualizaLista: false,
        });
    }

    componentDidMount() {
        document.getElementById("nombreDashboard").innerText = "Alertas Generadas";

        this.getSubProductos();
        this.getUsuarios(this.state.selectedTab);
        this.getAlertas(this.state.selectedTab);
    }

    componentWillUnmount() {
    }

    render() {
        return (
            <div className="content">
                <Row className="d-flex justify-content-center">
                    <Col lg="11">
                        <Card className="StaticCard HeaderCard">
                            <CardHeader>
                                <Row style={{ color: "white" }}>
                                    <Col md="3" lg="3">
                                        <span
                                            className="dx-field-label"
                                            style={{ color: "white", fontSize: "1em", fontWeight: "bold" }}
                                        >
                                            SubProducto:
                                        </span>
                                        <SelectBox
                                            dataSource={this.state.subProductos}
                                            searchEnabled={true}
                                            noDataText="No hay SubProductos"
                                            placeholder="SubProducto"
                                            valueExpr="claSubProducto"
                                            displayExpr="nomSubProducto"
                                            value={this.state.subProductoSelected}
                                            onValueChanged={this.onSelectSubProducto}
                                            className="CustomSelect"
                                        />
                                    </Col>
                                    <Col md="4" lg="5"></Col>
                                    
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Row className="align-items-start m-1">
                                    <Col md="5" className="d-inline">
                                        <Row>
                                            <div className="NavPadreAlerta mr-2">
                                                <Button
                                                    id="btnTodas"
                                                    className="NavItemAlerta NavAlerta NavAlertaActive"
                                                    onClick={this.onClickNav}
                                                >
                                                    Todas
                                                </Button>
                                                <Button
                                                    id="btnPendientes"
                                                    className="NavItemAlerta NavAlerta"
                                                    onClick={this.onClickNav}
                                                >
                                                    Pendientes
                                                </Button>
                                                <Button
                                                    id="btnResueltas"
                                                    className="NavItemAlerta NavAlerta"
                                                    onClick={this.onClickNav}
                                                >
                                                    Resueltas
                                                </Button>
                                            </div>
                                            {/* <div className="d-flex flex-row align-items-center">
                                                <div className="PaginacionAlertas">
                                                    <Button
                                                        className="BtnPaginacionAlertas mr-3"
                                                        onClick={this.onClickAnterior}
                                                    >
                                                        <i className="fas fa-chevron-left"></i>
                                                    </Button>
                                                    <Button
                                                        className="BtnPaginacionAlertas"
                                                        onClick={this.onClickSiguiente}
                                                    >
                                                        <i className="fas fa-chevron-right"></i>
                                                    </Button>
                                                </div>
                                            </div> */}
                                        </Row>
                                    </Col>
                                    <Col md="3"></Col>
                                    <Col md="3"></Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row className="d-flex justify-content-center">
                    <Col sm="11">
                        <Row className="align-items">
                            <Col lg="10">
                                <ListaAlertas
                                    alertas={this.state.alertas}
                                    paginaActual={this.state.paginaActual}
                                    usuarioAdmin={1}
                                />
                            </Col>
                            <Col lg="2">
                                <Card className="StaticCard FiltroCard">
                                    <CardBody>
                                        <Row className="">
                                            <Col className="d-inline">
                                                <h5>
                                                    <div className="d-flex flex-row align-items-center">
                                                        Filtrar por
                                                    </div>
                                                </h5>
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col className="d-block">
                                                <div className="">Fecha Recibido</div>
                                                <div>
                                                    <span className="dx-field-label">Desde:</span>
                                                    <DateBox
                                                        defaultValue={this.state.fechaDesdeRecibido}
                                                        type="date"
                                                        onValueChanged={this.onChangedFechaDesdeRecibido}
                                                    />
                                                </div>
                                                <div>
                                                    <span className="dx-field-label">Hasta:</span>
                                                    <DateBox
                                                        defaultValue={this.state.fechaHastaRecibido}
                                                        type="date"
                                                        onValueChanged={this.onChangedFechaHastaRecibido}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="d-block mt-1">
                                                <div className="">Usuarios</div>
                                                <SelectBox
                                                    dataSource={this.state.usuarios}
                                                    searchEnabled={true}
                                                    noDataText="No hay Usuarios"
                                                    placeholder="Usuarios"
                                                    displayExpr="nombre"
                                                    valueExpr="idUsuario"
                                                    value={this.state.usuarioSelected}
                                                    onValueChanged={this.onSelectedUsuario}
                                                    className=""
                                                />
                                            </Col>
                                            <Col className="mt-1">
                                                <div className="">Prioridad</div>
                                                <SelectBox
                                                    dataSource={this.state.prioridades}
                                                    searchEnabled={true}
                                                    noDataText="No hay Prioridades"
                                                    placeholder="Prioridad"
                                                    valueExpr="claPrioridad"
                                                    displayExpr="descripcion"
                                                    value={this.state.prioridadSelected}
                                                    onValueChanged={this.onSelectPrioridad}
                                                    className=""
                                                />
                                            </Col>
                                            <Col>
                                                <Row className="d-flex justify-content-center">
                                                    <div id="btnFiltrar" className="no-border text-center">
                                                        <button
                                                            className="btn btn-primary btn-round"
                                                            onClick={this.onClickFiltrar}
                                                        >
                                                            Consultar
                                                        </button>
                                                    </div>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default AlertasSubproducto;
