import React, { Component } from "react";
import PerfectScrollbar from "perfect-scrollbar";
//import PropTypes from "prop-types";
import logo_kiwi from "../../assets/img/kiwi_Icon.png";
import { Nav, Collapse } from "reactstrap";
import { Link } from "react-router-dom";
import { Button } from "devextreme-react/data-grid";
//import { Nav, Collapse, NavItem } from "reactstrap";
//import { NavLink } from "react-router-dom";

//Componente manejador del SideBar
var ps;
class SideBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...this.getCollapseStates(props.menu),
        };

        this.sidebar = React.createRef();
    }
    getCollapseStates = (menu) => {
        let initialState = {};
        menu.map((prop, key) => {
            if (prop.collapse) {
                initialState = {
                    [prop.state]: this.getCollapseInitialState(prop.views),
                    ...this.getCollapseStates(prop.views),
                    ...initialState,
                };
            }
            return null;
        });
        return initialState;
    };
    getCollapseInitialState(menu) {
        for (let i = 0; i < menu.length; i++) {
            if (menu[i].collapse && this.getCollapseInitialState(menu[i].views)) {
                return true;
            } else if (window.location.href.indexOf(menu[i].path) !== -1) {
                return true;
            }
        }
        return false;
    }
    createLinks = (menu) => {
        return menu.map((prop, key) => {
            if (prop.collapse) {
                var st = {};
                st[prop["state"]] = !this.state[prop.state];
                return (
                    <li className={this.getCollapseInitialState(prop.views) ? " active" : " "} key={key}>
                        <a
                            href=""
                            data-toggle="collapse"
                            aria-expanded={this.state[prop.state]}
                            onClick={(e) => {
                                e.preventDefault();
                                this.setState(st);
                            }}
                        >
                            {prop.icon !== undefined ? (
                                <>
                                    <i className={prop.icon} />
                                    <p style={{ color: "white" }}>
                                        {prop.name}
                                        <b className="caret" />
                                    </p>
                                </>
                            ) : (
                                <>
                                    <span className="sidebar-mini-icon">{prop.mini}</span>
                                    <span className="sidebar-normal">
                                        {prop.name}
                                        <b className="caret" />
                                    </span>
                                </>
                            )}
                        </a>
                        <Collapse isOpen={this.state[prop.state]}>
                            <ul className="nav">{this.createLinks(prop.views)}</ul>
                        </Collapse>
                    </li>
                );
            }
            return (
                <li className={this.activeRoute(prop.link)} key={key}>
                    <a href={prop.link}>
                        {prop.icon !== undefined ? (
                            <>
                                <i className={prop.icon} />
                                <p style={{ color: "white", fontWeight: "bold", fontSize: 12 }}>{prop.name}</p>
                            </>
                        ) : (
                            <>
                                <span className="sidebar-mini-icon">{prop.mini}</span>
                                <span className="sidebar-normal">{prop.name}</span>
                            </>
                        )}
                    </a>
                </li>
            );
        });
    };
    activeRoute = (routeName) => {
        return this.props.location.pathname === routeName ? " active" : "";
    };
    componentDidMount() {
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(this.sidebar.current, {
                suppressScrollX: true,
                suppressScrollY: false,
            });
        }
    }
    componentWillUnmount() {
        if (navigator.platform.indexOf("Win") > -1) {
            ps.destroy();
        }
        var id = window.setTimeout(null, 0);
        while (id--) {
            window.clearTimeout(id);
        }
    }
    render() {
        return (
            <div className="sidebar" data-color="orange">
                <div className="logo">
                    <Link id="logo-mini" to="/landing" className="simple-text logo-mini">
                        <img id="logo1" src={logo_kiwi} alt="Kiwi" />
                    </Link>
                    <Link id="logo-normal" to="/landing" className="simple-text logo-normal">
                        Kiwi
                    </Link>
                    <div className="navbar-minimize">
                        <Button
                            outline
                            className="btn-round btn-icon"
                            color="neutral"
                            id="minimizeSidebar"
                            onClick={() => this.props.minimizeSidebar()}
                        >
                            <i className="now-ui-icons text_align-center visible-on-sidebar-regular" />
                            <i className="now-ui-icons design_bullet-list-67 visible-on-sidebar-mini" />
                        </Button>
                    </div>
                </div>
                <div className="sidebar-wrapper" ref={this.sidebar}>
                    {/* Aqui Va el Menu */}
                    <Nav>{this.createLinks(this.props.menu)}</Nav>
                </div>
            </div>
        );
    }
}
export default SideBar;
