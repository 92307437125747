import React, { Component } from "react";
import { config } from "../../utils/config";
import { Router, Route, Redirect, Link, Switch, BrowserRouter } from "react-router-dom";
import { createBrowserHistory } from "history";
import { Row, Col } from "reactstrap";
import {
    setSessionData,
    logOut,
    showNotify,
    notifyType,
    notifyPosition,
    callApi,
    sessionAlive,
} from "../../utils/utils";

import logo_kiwi from "../../assets/img/kiwi_black.png";
import logo_dlabs from "../../assets/img/logo_dlabs.png";

import RecuperaPass from "./RecuperaPass";
import CambiaPass from "./CambiaPass";
import Layout from "../../layout/Layout";
import "../../assets/css/Login.css";
import { metricaAccion } from "../../utils/metrica";

const hist = createBrowserHistory();

//Componente manejador de Inicio de sesion
class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userMenu: null,
            nomUsuario: "",
            isLoging: false,
        };

        //bindeo la funcion de Login
        this.onSubmit = this.onSubmit.bind(this);
        this.onLogin = this.onLogin.bind(this);
    }
    onLogin(response) {
        //Guardo en el estado y en la session el nombre del usuario y el Token
        if (response) {
            //Solo si tiene un token
            if (response.token) {
                setSessionData({
                    NomUsuario: response.nombreUsuario,
                    Token: response.token,
                });

                //Guardo en estado el nombre del usuario
                this.setState({ nomUsuario: response.nombreUsuario });

                metricaAccion(window.location.href, "Entro a Kiwi!");
            } else {
                showNotify("Usuario y/o Contraseña incorrecta!", notifyPosition.centerBottom, notifyType.error);
            }
        }

        this.setState({ isLoging: false });
    }
    async onSubmit(event) {
        //Cancelo el Submit Refresh
        event.preventDefault();

        //Obtengo los datos del form de Inicio de Sesion
        const dataForm = new FormData(document.getElementById("formLogin"));

        //Datos del usuario
        let jSonParams = { userName: "", password: "" };

        jSonParams.userName = dataForm.get("user");
        jSonParams.password = dataForm.get("pass");

        //Valido los datos
        if (!jSonParams.userName || 0 === jSonParams.userName.length)
            showNotify("Favor de capturar el Usuario", notifyPosition.centerBottom, notifyType.error.length);
        else if (!jSonParams.password || 0 === jSonParams.password)
            showNotify("Favor de capturar la Contraseña", notifyPosition.centerBottom, notifyType.error.length);
        else {
            //Hago una peticion al Servicio de Login
            let url = config.urlSweetAPI + "/Login/authenticate";
            let method = "POST";
            
            this.setState({ isLoging: true });
            
            let callback = (result) => {
                this.onLogin(result);
                
            };

            await callApi(url, method, jSonParams, callback);
        }
    }
    componentDidMount() {}
    render() {
        //Manejamos la sesion
        if (sessionAlive()) {
            return (
                <Router history={hist}>
                    <BrowserRouter>
                        <Switch>
                            <Route
                                path="/Layout"
                                render={(props) => <Layout nomUsuario={this.state.nomUsuario} {...props} />}
                            />
                            <Redirect from="/" to="/Layout" />
                        </Switch>
                    </BrowserRouter>
                </Router>
            );
        } else {
            logOut();
        }

        //Se construye la vista de login
        const Login = () => {
            return (
                <div className="container-login100">
                    <div className="wrap-login100 p-t-85 p-b-20">
                        <span className="login100-form-avatar text-center">
                            <img src={logo_kiwi} alt="Kiwi DTO" />
                            <span className="versionLogin">Versión 4.0.0</span>
                        </span>

                        <form id={"formLogin"} method="POST" className="needs-validation" noValidate>
                            <div className="form-group">
                                <div
                                    className="wrap-input100 validate-input m-t-130 m-b-35"
                                    data-validate="Capture Usuario"
                                >
                                    <span className="input100-span">Usuario</span>
                                    <input id="userIdLogin" className="input100" type="text" name="user" required />
                                </div>

                                <div className="wrap-input100 validate-input m-b-50" data-validate="Capture Contraseña">
                                    <span className="input100-span">Contraseña</span>
                                    <input
                                        id="passwordIdLogin"
                                        className="input100"
                                        type="password"
                                        name="pass"
                                        required
                                    />
                                </div>

                                <div className="container-login100-form-btn">
                                    <button
                                        type="submit"
                                        id="btnEnter"
                                        className="login100-form-btn"
                                        disabled={this.state.isLoging}
                                        onClick={this.onSubmit}
                                    >
                                        {!this.state.isLoging ? (
                                            "Login"
                                        ) : (
                                            <div style={{ color: "white" }}>
                                                <i className="fas fa-kiwi-bird fa-pulse fa-2x fa-fw"></i>
                                            </div>
                                        )}
                                    </button>
                                </div>
                            </div>
                        </form>

                        <div className="login-more p-t-70">
                            <Row className="m-b-8">
                                <Col>
                                    <Link to="/recuperapass" className="txt2">
                                        ¿Olvidaste tu contraseña?
                                    </Link>{" "}
                                    |
                                    <Link to="/cambiapass" className="txt2">
                                        &nbsp;Cambiar contraseña
                                    </Link>
                                </Col>
                            </Row>
                        </div>
                        <div className="container-login100-form-btn">
                            <span style={{ textAlign: "center" }}>
                                Powered by <img src={logo_dlabs} alt="DLABS" className="powered-logo" />
                            </span>
                        </div>
                    </div>
                </div>
            );
        };

        return (
            <Router history={hist}>
                <BrowserRouter>
                    <Switch>
                        <Route path="/login" component={Login} />
                        <Route path="/recuperapass" component={RecuperaPass} />
                        <Route path="/cambiapass" component={CambiaPass} />
                        {/* <Redirect from="/" to="/login" /> */}
                    </Switch>
                </BrowserRouter>
            </Router>
        );
    }
}

export default Login;
