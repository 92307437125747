export const graficaCircular = `

/*Metodo de carga de datos*/
fillData() {

    showLoad();

    let krakenParams = {
        ClaProducto: 3,
        IdEntidad: 6,
        TipoEstructura: 1,
        ParamsSP: null,
        Callback: null,
    };

    //Parametros que recibe el servicio
    krakenParams.ParamsSP = {
        ClaTipoComponente: 1,
        ClaTipoPlantilla: 8
    };

    krakenParams.Callback = (response) => {
     
        /*Aqui va el proceso de los datos devueltos por la entidad
        Documentacion de ayuda: https://www.highcharts.com/demo/pie-basic */

        let grafica = this.getChartOptions();
        
        var listaValores =[];

        response.Result0.forEach((elemento)=>{
            let dato ={};
             dato.name = elemento.Nombre;
             dato.y = elemento.Valor;
             listaValores.push(dato);
        });

        grafica.series[0].data = listaValores;
       
        this.setState({
            options: grafica,
            detalle: response.Result1,
        });
    
        /*Instruccion Usada para resizear la grafica a su contenedor*/
        this.graficaRef.current.chart.reflow();

        hideLoad();
    };

    /*Hacemos la peticion para el servicio el indicador*/
    callKrakenApi(
        krakenParams.ClaProducto,
        krakenParams.IdEntidad,
        krakenParams.ParamsSP,
        krakenParams.TipoEstructura,
        krakenParams.Callback
    );
}
getChartOptions() {
   return {
    chart: {
        type: "pie",
        height: 300,
    },
    title: {
        text: "",
    },
    xAxis: {
        categories: null,
        crosshair: true,
    },

    yAxis: {
        min: 0,
        title: "",
    },
    tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
            '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0;"><b>{point.y}</b></td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
    },
    plotOptions: {
        pie: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
                enabled: true,
                format: "<b>{point.name}</b>: {point.y}",
                connectorColor: "silver",
            },
        },
        series: {
            cursor: "pointer",
            point: {
                events: {
                    click: (e) => {
                        alert(e.point);
                    },
                },
            },
        },
    },
    series: [
        {
            color: "rgb(75, 181, 255)",
            name: "Clientes",
            data: null,
        }
    ],
    credits: {
        enabled: false,
    },
    exporting: {
        enabled: false,
    },
    };
}`;
