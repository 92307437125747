export const popUpDetalle = `
/* Popup del Detalle #TD */
const PopUpDetalle = (
    <Popup
        key={"popUp#TX"}
        id={"popUp#TX"}
        visible={this.state.popupDetalle}
        onHiding={this.hidePopUpDetalle}
        dragEnabled={false}
        closeOnOutsideClick={true}
        showTitle={true}
        width={700}
        height={600}
    >
        <ToolbarItem render={renderIconTicket} location={"before"}></ToolbarItem>
        <ToolbarItem
            render={renderExportButton}
            location={"after"}
            referenciaGrid={(this.gridDetalleRef = React.createRef())}
        ></ToolbarItem>
        {TicketsDetalle}
        <DataGrid
            key={"grid#TX"}
            id={"grid#TX"}
            dataSource={this.state.detalle}
            ref={this.gridDetalleRef}
            options={{
                grouping: {
                    autoExpandAll: false,
                },
                groupPanel: {
                    visible: false,
                    allowColumnDragging: false,
                },
                columnHidingEnabled: true,
                columnAutoWidth: false,
                showBorders: false,
                showColumnHeaders: true,
                showColumnLines: false,
                showRowLines: true,
                noDataText: "Sin Registros",
                wordWrapEnabled: true,
                rowAlternationEnabled: false,
            }}
            columns={[
                {
                    alignment: "center",
                    dataField: "Columna1",
                    caption: "Col1",
                    width: 150,
                    allowResizing: false,
                },
                {
                    alignment: "center",
                    dataField: "Columna2",
                    caption: "Col2",
                    width: 100,
                    allowResizing: false,
                },
                {
                    alignment: "center",
                    dataField: "Columna3",
                    caption: "Col3",
                    width: 150,
                    allowResizing: false,
                },
                {
                    alignment: "center",
                    dataField: "Columna4",
                    caption: "Col4",
                    width: 150,
                    allowResizing: false,
                },
                {
                    alignment: "center",
                    dataField: "Columna5",
                    caption: "Col5",
                    width: 150,
                    allowResizing: false,
                },
            ]}
            summary={{
                totalItems: [
                    {
                        name: "Acumulado1",
                        showInColumn: "Col5",
                        displayFormat: "Acumulado: {0}",
                        valueFormat: "#,##0",
                        summaryType: "custom",
                    },
                ],
                calculateCustomSummary: function (options) {
                    if (options.name === "Acumulado1") {
                        if (options.summaryProcess === "start") {
                            options.totalValue = 0;
                        }
                        if (options.summaryProcess === "calculate") {
                            options.totalValue = options.totalValue + options.value.Columna5;
                        }
                    }
                },
            }}
            visible={true}
            height={500}
            width={700}
            onExporting={this.onExporting}
            noDataText={"Sin Registros"}
            wordWrapEnabled={true}
        >
            <Export enabled={false} allowExportSelectedData={true} />
        </DataGrid>
    </Popup>
);`;
