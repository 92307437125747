export const graficaMixta = `

/*Metodo de carga de datos*/
fillData() {

    showLoad();

    let krakenParams = {
        ClaProducto: 3,
        IdEntidad: 6,
        TipoEstructura: 1,
        ParamsSP: null,
        Callback: null,
    };

    //Parametros que recibe el servicio
    krakenParams.ParamsSP = {
        ClaTipoComponente: 1,
        ClaTipoPlantilla:9
    };

    krakenParams.Callback = (response) => {
     
        /*Aqui va el proceso de los datos devueltos por la entidad
        Documentacion de ayuda: https://www.highcharts.com/demo/column-basic */

        let grafica = this.getChartOptions();
        
        let listaValores1 = [],
        listaValores2 = [],
        listaValores3 = [],
        listaValores4 = [];

        let listaCategorias = [];

        response.Result0.forEach((element) => {
            listaValores1.push(element.Valor1);
            listaValores2.push(element.Valor2);
            listaValores3.push(element.Valor3);
            listaValores4.push(element.Valor4);
            listaCategorias.push(element.Producto);
        });
    
        grafica.yAxis.title.text = "Valores";
    
        grafica.tooltip.valueSuffix = " valores";
        
        grafica.series[0].data = listaValores1;
        grafica.series[1].data = listaValores2;
        grafica.series[2].data = listaValores3;
        grafica.series[3].data = listaValores4;
        grafica.xAxis.categories = listaCategorias;
    
        this.setState({
            options: grafica,
            detalle: response.Result1,
        });
    
        /*Instruccion Usada para resizear la grafica a su contenedor*/
        this.graficaRef.current.chart.reflow();

        hideLoad();
    };

    /*Hacemos la peticion para el servicio el indicador*/
    callKrakenApi(
        krakenParams.ClaProducto,
        krakenParams.IdEntidad,
        krakenParams.ParamsSP,
        krakenParams.TipoEstructura,
        krakenParams.Callback
    );
}
getChartOptions() {
   return {
    chart: {
        zoomType: "xy",
        height: 300,
    },
    title: {
        text: "",
    },
    xAxis: {
        categories: null,
        crosshair: true,
    },

    yAxis: {
        min: 0,
        title: {
            text: "",
        },
    },
    tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
            '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0;"><b>{point.y}</b></td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
    },
    plotOptions: {
        column: {
            pointPadding: 0.2,
            borderWidth: 0,
            borderColor: "#000000",
            dataLabels: {
                enabled: false,
                format: "{point.y} Ton",
            },
        },
        series: {
            cursor: "pointer",
            point: {
                events: {
                    click: (e) => {
                      console.log(e.point);
                    },
                },
            },
        },
    },
    series: [
        {
            color: "rgba(75, 181, 255, 0.5)",
            type: "column",
            name: "Valores 1",
            data: null,
        },
        {
            color: "rgba(40, 167, 69, 0.5)",
            type: "column",
            name: "Valores 2",
            data: null,
        },
        {
            color: "rgb(75, 181, 255)",
            type: "spline",
            name: "Valores 3",
            data: null,
        },
        {
            color: "rgb(40, 167, 69)",
            type: "spline",
            name: "Valores 4",
            data: null,
        },
    ],
    credits: {
        enabled: false,
    },
    exporting: {
        enabled: false,
    },
};
}`;