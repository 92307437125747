import React, { Component } from "react";
import "../../assets/css/LandingPage.css";
// import { Player } from "video-react";
import logo_kiwi from "../../assets/img/kiwi_Icon.png";
import DashboardLogistica3 from "../../assets/img/DashboardLogistica3.png";
import logo_dlabs from "../../assets/img/logo_dlabs.png";
import kiwiVideo from "../../assets/img/KiwiDTO.mp4";
import { Router, Route, Link, Switch, BrowserRouter, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";

import Login from "../Login/Login";
import Layout from "../../layout/Layout";

const hist = createBrowserHistory();
class Landing extends Component {
    componentDidMount() {}
    render() {
        const Landing = () => {
            return (
                <div className="page-top">
                    <nav
                        className="navbar navbar-expand-lg navbar-dark fixed-top navbar-shrink navbar-shrink-blue"
                        id="mainNav"
                    >
                        <div className="container">
                            <a className="navbar-brand js-scroll-trigger" href="#page-top">
                                <img src={logo_kiwi} />
                            </a>
                            <div
                                className="navbar-toggler navbar-toggler-right"
                                type="button"
                                data-toggle="collapse"
                                data-target="#navbarResponsive"
                                aria-controls="navbarResponsive"
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                            >
                                Menu<i className="fas fa-bars ml-1"></i>
                            </div>
                            <div className="collapse navbar-collapse" id="navbarResponsive">
                                <ul className="navbar-nav text-uppercase ml-auto">
                                    <li className="nav-item">
                                        <a className="nav-link js-scroll-trigger" href="#sobreKiwi">
                                            Sobre Kiwi
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link js-scroll-trigger" href="#portfolio">
                                            Portafolio
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/login" className="nav-link js-scroll-trigger">
                                            <i className="fas fa-sign-in-alt"></i> Ingresa
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                    {/* <!--Encabezado--> */}
                    <header className="masthead">
                        <div className="backgroudMastHeader">
                            <div className="container">
                                <div className="masthead-heading text-left">
                                    Better Data.
                                    <br />
                                    Better UX.
                                    <br />
                                    Right Timing.
                                </div>
                            </div>
                        </div>
                    </header>
                    <div id="sobreKiwi">
                        {/* <!--Servicios--> */}
                        <div className="page-section" id="servicios">
                            <div className="container">
                                <div className="text-center">
                                    <h2 className="h2 section-heading text-uppercase">Sobre KIWI</h2>
                                    {/* <!--<h3 className="section-subheading text-muted">Lorem ipsum dolor sit amet consectetur.</h3>--> */}
                                </div>
                                <div className="row text-center">
                                    <div className="col-md-4">
                                        <span className="fa-stack fa-4x">
                                            <i className="fas fa-circle fa-stack-2x text-primary"></i>
                                            <i className="fas fa-share-alt fa-stack-1x fa-inverse"></i>
                                        </span>
                                        <h4 className=" h4 my-3">BETTER DATA</h4>
                                        <p className="p text-muted">
                                            Información confiable, relevante, validada y sin barreras.
                                        </p>
                                    </div>
                                    <div className="col-md-4">
                                        <span className="fa-stack fa-4x">
                                            <i className="fas fa-circle fa-stack-2x text-primary"></i>
                                            <i className="fas fa-laptop fa-stack-1x fa-inverse"></i>
                                        </span>
                                        <h4 className="h4 my-3">BETTER UX</h4>
                                        <p className="p text-muted">
                                            Visualización rápida, simple y efectiva. Olvida los múltiples documentos de
                                            Excel.
                                        </p>
                                    </div>
                                    <div className="col-md-4">
                                        <span className="fa-stack fa-4x">
                                            <i className="fas fa-circle fa-stack-2x text-primary"></i>
                                            <i className="fas fa-history fa-stack-1x fa-inverse"></i>
                                        </span>
                                        <h4 className="h4 my-3">RIGHT TIMING</h4>
                                        <p className="p text-muted">
                                            Información accesible en tiempo real. Agilizando la toma de decisiones.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="page-section bg-light" id="servicios">
                        <div className="container">
                            <div className="row text-center">
                                <div className="col-12">
                                    <div className="embed-responsive embed-responsive-21by9">
                                        <video controls autoPlay>
                                            {/* <source src="../../assets/img/KiwiDTO.mp4"type="video/mp4"></source> */}
                                            <source src={kiwiVideo} type="video/mp4" />
                                        </video>
                                        {/* <Player playsInline src="../../assets/img/KiwiDTO.mp4" /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!--Grid Portafolio--> */}
                    <div className="page-section" id="portfolio">
                        <div className="container">
                            <div className="text-center">
                                <h2 className="h2 section-heading text-uppercase">Portafolio</h2>
                                {/* <!--<h3 className="section-subheading text-muted">Lorem ipsum dolor sit amet consectetur.</h3>--> */}
                            </div>
                            <div className="row d-flex justify-content-center">
                                <div className="col-lg-4 col-sm-6 mb-4">
                                    <div className="portfolio-item">
                                        <a className="portfolio-link" data-toggle="modal" href="#portfolioModal">
                                            <div className="portfolio-hover">
                                                <div className="portfolio-hover-content">
                                                    <i className="fas fa-plus fa-3x"></i>
                                                </div>
                                            </div>
                                            <img
                                                className="img-fluid img-thumbnail img-portfolio"
                                                src={DashboardLogistica3}
                                                alt=""
                                            />
                                        </a>
                                        <div className="portfolio-caption">
                                            <div className="portfolio-caption-heading">Dashboard Logística</div>
                                            <div className="portfolio-caption-subheading text-muted">
                                                Acceso a información
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- Footer--> */}
                    <div className="footer py-4">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-4 text-lg-left">Copyright © kiwi.deacero.com 2020</div>
                                <div className="col-lg-4 text-center">
                                    Powered by <img src={logo_dlabs} alt="DLabs" className="powered-logo"></img>
                                </div>
                                <div className="col-lg-4 text-lg-right">
                                    <a className="mr-3" href="#!">
                                        Aviso de Privacidad
                                    </a>
                                    <a href="#!">Condiciones de Uso</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="portfolio-modal modal fade"
                        id="portfolioModal"
                        tabIndex="-1"
                        role="dialog"
                        aria-hidden="true"
                    >
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="close-modal" data-dismiss="modal">
                                    <i className="fas fa-window-close fa-stack-2x text-primary"></i>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-8">
                                        <div className="modal-body">
                                            {/* <!-- Project Details Go Here--> */}
                                            <h2 className="h2 text-uppercase">Dashboard Logística</h2>
                                            <p className="p item-intro text-muted">Macrohub y CEDIS</p>
                                            <img
                                                className="img-fluid img-thumbnail d-block mx-auto"
                                                src="../../assets/img/DashboardLogistica3.png"
                                                alt=""
                                            />
                                            <p className="p">
                                                Permite visualizar al área de logística la deuda actual que se tiene con
                                                clientes y traspasos, así como lo que no tiene en inventario.
                                            </p>
                                            <ul className="list-inline">
                                                <li>Junio 2019</li>
                                            </ul>
                                            <div className="btn btn-primary" data-dismiss="modal" type="button">
                                                <i className="fas fa-times mr-1"></i>Cerrar
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        };

        return (
            <Router history={hist}>
                <BrowserRouter>
                    <Switch>
                        <Route path="/landing" component={Landing} />
                        <Route path="/login" component={Login} />
                        <Route path="/Layout" component={Layout} />

                        {/*Si se ingresa un path no adecuado, te regresa a landing, excepto una vez en Layout.*/}
                        {/* <Redirect to='/landing'/> */}
                    </Switch>
                </BrowserRouter>
            </Router>
        );
    }
}
export default Landing;
