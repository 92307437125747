export const graficaBarras = `

/*Metodo de carga de datos*/
fillData() {

    showLoad();

    let krakenParams = {
        ClaProducto: 3,
        IdEntidad: 6,
        TipoEstructura: 1,
        ParamsSP: null,
        Callback: null,
    };

    //Parametros que recibe el servicio
    krakenParams.ParamsSP = {
        ClaTipoComponente: 1,
        ClaTipoPlantilla: 1
    };

    krakenParams.Callback = (response) => {
     
        /*Aqui va el proceso de los datos devueltos por la entidad
        https://www.highcharts.com/demo/bar-basic */

        let grafica = this.getChartOptions();
        
        let listaValores = [];
        let listaCategorias =[];

        response.Result0.forEach(element => {
           listaValores.push(element.Cantidad);
            listaCategorias.push(element.Producto)
        });
        
        grafica.yAxis.title.text = "Cantidad";

        grafica.tooltip.valueSuffix = " productos";

        grafica.series[0].data = listaValores;
        grafica.xAxis.categories = listaCategorias;
    
        this.setState({
            options: grafica,
            detalle: response.Result1,
        });
    
        /*Instruccion Usada para resizear la grafica a su contenedor*/
        this.graficaRef.current.chart.reflow();

        hideLoad();
    };

    /*Hacemos la peticion para el servicio el indicador*/
    callKrakenApi(
        krakenParams.ClaProducto,
        krakenParams.IdEntidad,
        krakenParams.ParamsSP,
        krakenParams.TipoEstructura,
        krakenParams.Callback
    );
}
getChartOptions() {
   return {
        chart: {
            type: "column",
            height: 400,
        },
        title: {
            text: "",
        },
        xAxis: {
            categories: null,
            crosshair: true,
        },

        yAxis: {
            min: 0,
            title: {
                text: "",
            },
        },
        tooltip: {
            headerFormat: "<span style=\'font-size:10px\'>{point.key}</span><table>",
            pointFormat:
                "<tr><td style=\'color:{series.color};padding:0\'>{series.name}: </td>" +
                "<td style=\'padding:0;\'><b>{point.y}</b></td></tr>",
            footerFormat: "</table>",
            shared: true,
            useHTML: true,
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    format: "{point.y}",
                },
            },
        },
        series: [
            {
                color: "rgb(75, 181, 255)",
                type: "column",
                name: "Productos",
                data: null,
                point: {
                    events: {
                        click: (e) => {
                           alert(e.point);
                        },
                    },
                },
            },
        ],
        credits: {
            enabled: false,
        },
        exporting: {
            enabled: false,
        },
    };
}`;
