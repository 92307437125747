export const filtroCheckBox = `import React, { Component } from "react";
import CheckBox from "devextreme-react/check-box";

class #Nombre extends Component {
    constructor(props) {
        super(props);

        this.state = {
            checkBoxValue: false,
        };

        this.onValueChanged = this.onValueChanged.bind(this);
    }
    onValueChanged(e) {
        this.setState({
            checkBoxValue: e.value,
        });

        if (this.props.onSelectCallBack) this.props.onSelectCallBack(this.state.checkBoxValue ? 1 : 0);
    }
    componentDidMount() {}
    render() {
        return (
            <div id="filtro#TDX" style={{ marginLeft: "5px", marginRight: "5px", alignSelf: "flex-center", cursor: "pointer" }}>
                <CheckBox defaultValue={false} onValueChanged={this.onValueChanged} />
                &nbsp;
                <label style={{ color: "white" }}>#TD </label>
            </div>
        );
    }
}

export default #Nombre ;`;
