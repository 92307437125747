import React, { Component } from "react";
import SelectComponente from "./SelectComponente";
import TextBoxComponente from "./TextBoxComponente";

class Componente extends Component {
    constructor(props) {
        super(props);

        this.state = {
            componentePlantilla: null,
            titulo: "",
            archivo: "",
        };
    }
    /*Maneja la eliminacion del componente para enviar el Callback al padre "Generador" */
    removeComponente = () => {
        this.setState({ componentePlantilla: null, titulo: "", archivo: "" });

        if (this.props.onRemoveComponente) this.props.onRemoveComponente(this);
    };
    /*Maneja la actualizacion cuando se selecciona un tipo*/
    onUpdateSelectComponente = (select) => {
        this.setState({ componentePlantilla: select });
    };
    /*Maneja la actualizacion del titulo */
    onUpdateTitulo = (value) => {
        this.setState({ titulo: value });
    };
    /*Maneja la actualizacion del archivo*/
    onUpdateArchivo = (value) => {
        this.setState({ archivo: value });
    };
    componentDidMount() {}
    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
        }

        if (this.props.onUpdateComponente) {
            //Validacion de campos por componente que no esten vacios
            if (this.state.componentePlantilla && this.state.titulo !== "" && this.state.archivo !== "") {
                let obj = {
                    id: this.props.id,
                    componentePlantilla: this.state.componentePlantilla,
                    titulo: this.state.titulo,
                    archivo: this.state.archivo,
                };
                this.props.onUpdateComponente(obj);
            }
        }
    }
    render() {
        return (
            <div
                className="container-fluid fade-in"
                style={{
                    height: 90,
                    margin: 10,
                    borderRadius: 10,
                    backgroundColor: "#f9f9f9",
                    borderColor: "#bcc0c5",
                    borderStyle: "solid",
                    borderWidth: 1,
                    boxShadow: "5px 6px 7px -3px #d0d0d0",
                }}
            >
                <button
                    className="btn btn-secondary btn-round float-right"
                    style={{ marginTop: 20 }}
                    onClick={this.removeComponente}
                >
                    <i className="fas fa-trash" style={{ fontSize: 14 }}></i>
                </button>
                <div className="d-flex">
                    <div style={{ marginTop: 25, marginLeft: 10, color: "rgb(136 136 136)" }}>
                        <i className="fas fa-cubes" style={{ fontSize: 25 }}></i>
                    </div>
                    <div style={{ width: 295, padding: 10, margin: 11, color: "#264d79", fontWeight: 500 }}>
                        <div style={{ borderRadius: 17, borderColor: "#e3e3e3", borderStyle: "solid", borderWidth: 1 }}>
                            <TextBoxComponente
                                className={"genTxt"}
                                value={this.state.titulo}
                                placeHolder="Titulo del Componente"
                                onUpdate={this.onUpdateTitulo}
                            />
                        </div>
                    </div>

                    <div style={{ width: 295, padding: 10, margin: 11, color: "#264d79", fontWeight: 500 }}>
                        <div style={{ borderRadius: 17 }}>
                            {" "}
                            <SelectComponente className={"genSelect"} onUpdate={this.onUpdateSelectComponente} />
                        </div>
                    </div>
                    <div style={{ width: 295, padding: 10, margin: 11, color: "#264d79", fontWeight: 500 }}>
                        <div style={{ borderRadius: 17, borderColor: "#e3e3e3", borderStyle: "solid", borderWidth: 1 }}>
                            <TextBoxComponente
                                className={"genTxt"}
                                value={this.state.archivo}
                                placeHolder="Nombre del archivo"
                                onUpdate={this.onUpdateArchivo}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Componente;
