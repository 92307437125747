import React, { Component } from "react";
import { Card, CardBody, FormGroup } from "reactstrap";
import Form, { Item, GroupItem, ButtonItem } from "devextreme-react/form";
import { Button } from "devextreme-react/button";
import { config } from "../../utils/config";
import { callApi, showNotify, notifyType, notifyPosition } from "../../utils/utils";

class UsuarioAlta extends Component {
    constructor(props) {
        super(props);

        this.state = {
            buscar: false,
            objUsuario: {
                ClaveEmpleado: "",
                ClavePerfil: "",
                NombreEmpleado: "",
            },
            subProductoSeleccionado: "",
            perfilSeleccionado: "",
            ubicacionSeleccionada: "",
            ubicaciones: null,
        };

        this.btnBuscarHandleSubmit = this.btnBuscarHandleSubmit.bind(this);
        this.btnGuardarHandleSubmit = this.btnGuardarHandleSubmit.bind(this);
        this.btnCancelarHandle = this.btnCancelarHandle.bind(this);
        this.refForm = React.createRef();
    }

    /**
     * Regresa el objeto del formulario
     */
    get formUser() {
        return this.refForm.current ? this.refForm.current.instance : null;
    }

    /**
     * Funcion que se ejecuta al dar clic en el boton Buscar
     */
    btnBuscarHandleSubmit() {
        let dataForm = this.formUser.option("formData");
        let claEmpleado = dataForm.ClaveEmpleado;

        if (claEmpleado === "") {
            showNotify("Favor de capturar una Clave de Empleado", notifyPosition.centerTop, notifyType.error);
            return;
        }

        let urlWebService = config.urlKiwiAPI + "usuarios/usuariosObtener/" + claEmpleado;

        try {
            const functionCallback = (res) => {
                this.setState({
                    objUsuario: {
                        ClaveEmpleado: claEmpleado,
                        NombreEmpleado: res.data[0].nombre,
                    },
                });
            };

            callApi(urlWebService, "GET", null, functionCallback).catch((err) => {
                showNotify("Ha ocurrido un error: " + err.message, notifyPosition.centerTop, notifyType.error);
                console.log("Error: " + err.message);
            });
        } catch (err) {
            showNotify("Ha ocurrido un error: " + err.message, notifyPosition.centerTop, notifyType.error);
            console.log("Error: " + err.message);
        }
    }

    /**
     * Funcion que se ejecuta al dar clic en el boton Guardar
     */
    btnGuardarHandleSubmit() {
        let dataForm = this.formUser.option("formData");
        let claPerfil = this.state.perfilSeleccionado.value;
        let claUbicacion = this.state.ubicacionSeleccionada.value;
        let claSubProducto = this.state.subProductoSeleccionado.value;

        if (
            dataForm.ClaveEmpleado === "" ||
            claPerfil === undefined ||
            claUbicacion === undefined ||
            claSubProducto === undefined
        ) {
            showNotify(
                "Favor de capturar una Clave de Empleado, Subproducto, Perfil y Ubicación",
                notifyPosition.centerTop,
                notifyType.error
            );
            return;
        }

        // Opcion para dar de alta
        let opcion = 1;
        let claEmpleado = dataForm.ClaveEmpleado;
        let urlWebService = config.urlKiwiAPI + "usuarios/usuariosCRUD/" + opcion;

        let body = {
            claEmpleado: claEmpleado,
            claPerfil: claPerfil,
            claUbicacion: claUbicacion,
            claSubProducto: claSubProducto,
        };

        try {
            const functionCallback = (res) => {
                showNotify("Usuario guardado correctamente", notifyPosition.centerTop, notifyType.success);
                this.props.handler(false);
                window.location.reload();
            };

            callApi(urlWebService, "PUT", body, functionCallback).catch((err) => {
                showNotify("Ha ocurrido un error: " + err.message, notifyPosition.centerTop, notifyType.error);
                console.log("Error: " + err.message);
            });
        } catch (err) {
            showNotify("Ha ocurrido un error: " + err.message, notifyPosition.centerTop, notifyType.error);
            console.log("Error: " + err.message);
        }
    }

    /**
     * Función del botón para cancelar la operación y ocultar el modal
     */
    btnCancelarHandle() {
        this.props.handler(false);
    }

    /**
     * Funcion que asigna el subproducto seleccionado a una variable
     * @param {Obj} select
     */
    onSubProductoChange = (select) => {
        this.setState({
            subProductoSeleccionado: select,
        });
        console.log(select);
    };

    /**
     * Funcion que asigna el tipo de perfil seleccionado a una variable
     * @param {Obj} select objeto seleccionado
     */
    onPerfilChange = (select) => {
        this.setState({
            perfilSeleccionado: select,
        });
    };

    /**
     * Funcion que asigna la ubicacion seleccionada a una variable
     * @param {Obj} select objeto seleccionado
     */
    onUbicacionChange = (select) => {
        this.setState({
            ubicacionSeleccionada: select,
        });
    };

    render() {
        return (
            <div>
                <Card>
                    <CardBody>
                        <Form
                            ref={this.refForm}
                            key={"formUser"}
                            id="formUser"
                            formData={this.state.objUsuario}
                            colCount={1}
                            showColonAfterLabel={true}
                            className="formUser"
                            id="FormAltaUsuario"
                        >
                            <GroupItem colSpan={2} colCount={2}>
                                <Item
                                    dataField="ClaveEmpleado"
                                    editorOptions={{
                                        placeholder: "Ej. 909090",
                                    }}
                                />
                                <ButtonItem
                                    itemType="button"
                                    horizontalAlignment="left"
                                    buttonOptions={{
                                        hint: "Buscar",
                                        icon: "search",
                                        type: "default",
                                        onClick: this.btnBuscarHandleSubmit,
                                    }}
                                />
                            </GroupItem>
                            <Item
                                dataField="NombreEmpleado"
                                editorOptions={{
                                    placeholder: "Se obtiene al buscar una Clave Empleado válida",
                                    disabled: true,
                                }}
                            />

                            <GroupItem colSpan={2} colCount={2}>
                                <Item
                                    dataField="SubProducto"
                                    editorType="dxSelectBox"
                                    editorOptions={{
                                        dataSource: this.props.subproductos,
                                        searchEnabled: true,
                                        valueExpr: "claSubProducto",
                                        displayExpr: "nomSubProducto",
                                        onValueChanged: this.onSubProductoChange,
                                        placeholder: "Selecciona un subproducto",
                                    }}
                                />
                                <Item
                                    dataField="Perfil"
                                    editorType="dxSelectBox"
                                    editorOptions={{
                                        dataSource: this.props.perfiles,
                                        searchEnabled: true,
                                        valueExpr: "claPerfil",
                                        displayExpr: "nomPerfil",
                                        onValueChanged: this.onPerfilChange,
                                        placeholder: "Selecciona un perfil",
                                    }}
                                />
                            </GroupItem>

                            <Item
                                dataField="Ubicación"
                                editorType="dxSelectBox"
                                editorOptions={{
                                    dataSource: this.props.ubicaciones,
                                    searchEnabled: true,
                                    valueExpr: "ClaUbic",
                                    displayExpr: "NomUbic",
                                    onValueChanged: this.onUbicacionChange,
                                    placeholder: "Selecciona una ubicación",
                                }}
                            />
                        </Form>
                        <FormGroup className="text-right">
                            <Button
                                id="btnUsersGuardar"
                                text="Guardar"
                                onClick={this.btnGuardarHandleSubmit}
                                className="btn btn-primary btn-round"
                            />
                            <Button
                                id="btnUsersCancelar"
                                text="Cancelar"
                                onClick={this.btnCancelarHandle}
                                className="btn btn-primary btn-round"
                            />
                        </FormGroup>
                    </CardBody>
                </Card>
            </div>
        );
    }
}

export default UsuarioAlta;
