import React, { Component } from "react";
import SelectBox from "devextreme-react/select-box";
import { Validator, RequiredRule } from "devextreme-react/validator";

const componentes = [
    {
        tipoPlantilla: "1-1",
        nombre: "Grafica de Barras",
    },
    {
        tipoPlantilla: "1-8",
        nombre: "Grafica Circular",
    },
    {
        tipoPlantilla: "1-9",
        nombre: "Grafica Mixta",
    },
    {
        tipoPlantilla: "1-10",
        nombre: "Grafica HotMap",
    },
    {
        tipoPlantilla: "1-11",
        nombre: "Grafica Sunburst",
    },
    {
        tipoPlantilla: "2-2",
        nombre: "Indicador Simple",
    },
    {
        tipoPlantilla: "3-7",
        nombre: "Indicador Doble",
    },
    {
        tipoPlantilla: "4-3",
        nombre: "Filtro-Boton",
    },
    {
        tipoPlantilla: "4-4",
        nombre: "Filtro-CheckBox",
    },
    {
        tipoPlantilla: "4-5",
        nombre: "Filtro-Seleccion",
    },
    {
        tipoPlantilla: "4-6",
        nombre: "Filtro-Multiseleccion",
    },
];

class SelectComponente extends Component {
    constructor(props) {
        super(props);

        this.state = { value: null };
    }
    onValueChanged = (e) => {
        if (this.props.onUpdate) this.props.onUpdate(e.value ? e.value.split("-") : e.value);

        this.setState({
            value: e.value,
        });
    };
    componentDidMount() {}
    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
        }
    }
    render() {
        return (
            <SelectBox
                className={this.props.className}
                items={componentes}
                placeholder="Tipo de Componente"
                displayExpr="nombre"
                valueExpr="tipoPlantilla"
                showClearButton={true}
                value={this.state.value}
                onValueChanged={this.onValueChanged}
            >
                <Validator>
                    <RequiredRule message="Este campo es requerido!" />
                </Validator>
            </SelectBox>
        );
    }
}

export default SelectComponente;
