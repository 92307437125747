export const graficaSunburst = `

/*Metodo de carga de datos*/
fillData() {

    showLoad();

    let krakenParams = {
        ClaProducto: 3,
        IdEntidad: 6,
        TipoEstructura: 1,
        ParamsSP: null,
        Callback: null,
    };

    //Parametros que recibe el servicio
    krakenParams.ParamsSP = {
        ClaTipoComponente: 1,
        ClaTipoPlantilla:11
    };

    krakenParams.Callback = (response) => {
     
        /*Aqui va el proceso de los datos devueltos por la entidad
        Documentacion de ayuda: https://www.highcharts.com/demo/column-basic */

        let grafica = this.getChartOptions();
        
        var listaValores = response.Result0;
        grafica.series[0].data = listaValores;
       
        this.setState({
            options: grafica,
            detalle: response.Result1,
        });
    
        /*Instruccion Usada para resizear la grafica a su contenedor*/
        this.graficaRef.current.chart.reflow();

        hideLoad();
    };

    /*Hacemos la peticion para el servicio el indicador*/
    callKrakenApi(
        krakenParams.ClaProducto,
        krakenParams.IdEntidad,
        krakenParams.ParamsSP,
        krakenParams.TipoEstructura,
        krakenParams.Callback
    );
}
getChartOptions() {
   return {
    chart: {
        height: '400px'
    },

    title: {
        text: ''
    },
    subtitle: {
        text: ''
    },
    series: [{
        type: "sunburst",
        data: null,
        allowDrillToNode: true,
        cursor: 'pointer',
        point: {
            events: {
                click: (e) => {
                    console.log(e.point);
                },
            },
        },
        dataLabels: {
            format: '{point.name}',
            filter: {
                property: 'innerArcLength',
                operator: '>',
                value: 16
            },
            rotationMode: 'circular'
        },
        levels: [{
            level: 1,
            levelIsConstant: false,
            dataLabels: {
                filter: {
                    property: 'outerArcLength',
                    operator: '>',
                    value: 64
                }
            }
        }, {
            level: 2,
            colorByPoint: true
        },
        {
            level: 3,
            colorVariation: {
                key: 'brightness',
                to: -0.5
            }
        }, {
            level: 4,
            colorVariation: {
                key: 'brightness',
                to: 0.5
            }
        }]

    }],
    tooltip: {
        headerFormat: "",
        pointFormat: 'La Población de <b>{point.name}</b> es <b>{point.value}</b>'
    }
   };
}`;
