export const graficaBase = `import React, { Component } from "react";
    import { Popup, ToolbarItem } from "devextreme-react/popup";
    import ExcelJS from "exceljs";
    import FileSaver from "file-saver";
    import DataGrid, { Export, MasterDetail } from "devextreme-react/data-grid";
    import { exportDataGrid } from "devextreme/excel_exporter";
    import Ticket from "../../components/core/Ticket";
    import Highcharts from "highcharts";
    import HighchartsReact from "highcharts-react-official";
    import { showLoad, hideLoad, callKrakenApi } from "../../utils/utils";
    import { renderExportButton } from "../../components/core/ExportExcelGrid";
    import {metricaAccion} from "../../utils/metrica";

    #ImpSUNBURTS
    #ImpMAPS
    
    class #Nombre extends Component {
        constructor(props) {
            super(props);
            this.state = {
                options: this.getChartOptions(),
                popupDetalle: false,
                detalle: null,
                popupTicketVisible: false,
                popupTicketDetalleVisible: false,
            };
            this.graficaRef = React.createRef();
        }

        /* Metodos de Control */
        #ImpMC

        verResumenDetalle = () => {

            /*Implementación de Métricas
            Este codigo es utilizado con fines estadisticos y de medicion, por favor no borrar la implementación, 
            en su defecto colocarla a criterio según sea el caso  */
            metricaAccion(window.location.href,"Click a detalle en componente #Nombre ");

            this.setState({ popupDetalle: true });
    
            this.fillData();
        };
        hidePopUpDetalle = () => {
            this.setState({ popupDetalle: false });
        };

        /* Implementacion de la grafica */
        #ImpGRF

        componentDidMount() {
            this.fillData();
        }
        componentDidUpdate(prevProps) {
            if (this.props !== prevProps) {
                this.setState({
                    options: {},
                });
    
                this.fillData();
            }
        }
        render() {

        /* Implementacion de Tickets(Levantamiento de Incidentes) */
        #ImpTKT
    
        /* Implementacion de PopUp Detalle */
        #ImpPD

            return (
                <div className="card card-chart">
                    <div className="card-header">
                        <div className="row">
                            <div className="col-8">
                                <div
                                    className="graf_tickets"
                                    style={{ display: "inline-flex", margin: "0px", padding: "0px" }}
                                >
                                    <h4 className="card-title">#TD</h4>
                                    {IconoTicket}
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="no-border d-flex justify-content-end">
                                    <button className="btn btn-primary btn-round " onClick={this.verResumenDetalle}>
                                        Ver Detalle
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body"  style={{minHeight:425,maxHeight:425}}>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={this.state.options}
                            constructorType={"#ImpCType"}
                            containerProps={{ style: { width: "100%" } }}
                            ref={this.graficaRef}
                        />
                        {Tickets}
                        {PopUpDetalle}
                    </div>
                </div>
            );
        }
    }
    
    export default #Nombre;
    `;
