export const metodosControl = `  
onClickTicket = (e) => {
    this.setState({
        popupTicketVisible: true,
    });
    e.stopPropagation();
};
hidePopUpTicket = () => {
    this.setState({
        popupTicketVisible: false,
    });
};
onClickTicketDetalle = () => {
    this.setState({
        popupTicketDetalleVisible: true,
    });
};
hidePopUpTicketDetalle = () => {
    this.setState({
        popupTicketDetalleVisible: false,
    });
};

handler = (val) => {
    this.setState({
        popupTicketVisible: val,
        popupTicketDetalleVisible: val,
    });
};`;
