import React from "react";
//import { Link } from "react-router-dom";
import {
    Collapse,
    Navbar,
    NavbarToggler,
    Nav,
    NavItem,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Container,
} from "reactstrap";

//import routes from "routes.js";
import { getSessionItem, logOut } from "../../utils/utils";

class Header extends React.Component {
    state = {
        isOpen: false,
        dropdownOpen: false,
        color: "transparent",
    };

    sidebarToggle = React.createRef();

    toggle = () => {
        if (this.state.isOpen) {
            this.setState({
                color: "transparent",
            });
        } else {
            this.setState({
                color: "white",
            });
        }
        this.setState({
            isOpen: !this.state.isOpen,
        });
    };
    dropdownToggle = (e) => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen,
        });
    };
    openSidebar = () => {
        document.documentElement.classList.toggle("nav-open");
        this.sidebarToggle.current.classList.toggle("toggled");
    };
    updateColor = () => {
        if (window.innerWidth < 993 && this.state.isOpen) {
            this.setState({
                color: "white",
            });
        } else {
            this.setState({
                color: "transparent",
            });
        }
    };

    componentDidMount() {
        window.addEventListener("resize", this.updateColor.bind(this));
    }
    componentDidUpdate(e) {
        if (
            window.innerWidth < 993 &&
            e.history.location.pathname !== e.location.pathname &&
            document.documentElement.className.indexOf("nav-open") !== -1
        ) {
            document.documentElement.classList.toggle("nav-open");
            this.sidebarToggle.current.classList.toggle("toggled");
        }
    }
    cerrarSesion() {
        logOut();
        window.location.href = "/login";
    }
    render() {
        return (
            <Navbar color="white" expand="lg" className="navbar-transparent navbar-absolute bg-light">
                <Container fluid>
                    <div className="navbar-wrapper">
                        <div className="navbar-toggle">
                            <button
                                type="button"
                                ref={this.sidebarToggle}
                                className="navbar-toggler"
                                onClick={() => this.openSidebar()}
                            >
                                <span className="navbar-toggler-bar bar1" />
                                <span className="navbar-toggler-bar bar2" />
                                <span className="navbar-toggler-bar bar3" />
                            </button>
                        </div>
                        <h2 id="nombreDashboard"></h2>
                    </div>
                    <NavbarToggler onClick={this.toggle}>
                        <span className="navbar-toggler-bar navbar-kebab" />
                        <span className="navbar-toggler-bar navbar-kebab" />
                        <span className="navbar-toggler-bar navbar-kebab" />
                    </NavbarToggler>
                    <Collapse isOpen={this.state.isOpen} navbar className="justify-content-end">
                        <Nav navbar>
                            <NavItem>
                                <h6 id="maninsession" style={{ paddingTop: 12, marginRight: 5 }}>
                                    {getSessionItem("NomUsuario", "")}
                                </h6>
                            </NavItem>
                            <Dropdown nav isOpen={this.state.dropdownOpen} toggle={(e) => this.dropdownToggle(e)}>
                                <DropdownToggle caret nav>
                                    <i className="fas fa-cog"></i>
                                    <p>
                                        <span className="d-lg-none d-md-block">Configuración</span>
                                    </p>
                                </DropdownToggle>
                                <DropdownMenu right>
                                    <DropdownItem tag="a" style={{ cursor: "pointer" }} onClick={this.cerrarSesion}>
                                        LogOut
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </Nav>
                    </Collapse>
                </Container>
            </Navbar>
        );
    }
}

export default Header;
